import React, {
  useEffect, useState, useRef, useContext,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button, Spacer, Spinner,
} from '@nextui-org/react';
import { Icon } from '@iconify/react';

import AuthContext from '@/auth/context';
import LicaPage from '@/components/LicaPage';
import useFetch from '@/hooks/useFetch';
import { getCurrentDeviceType } from '@/utils/common';

import BlogControls from './blog';
import VideoControls from './video';
import PodcastControls from './podcast';
import styles from './index.module.css';

function Generate() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [contentOptions, setContentOptions] = useState<any>({});
  const [isLoadingOptions, setIsLoadingOptions] = useState<boolean>(true);
  const [isUploading, setIsUploading] = useState(false);
  const [loadingMedia, setLoadingMedia] = useState<Set<string>>(new Set());

  const [prompt, setPrompt] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [mediaPreviews, setMediaPreviews] = useState<{ src: string, type: string }[]>([]);
  const [showControl, setShowControl] = useState(true);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const {
    data: getData,
    triggerFetch: triggerGetFetch,
  } = useFetch<any>(`${import.meta.env.VITE_BACKEND_URL}/api/v1/content-options/`, { method: 'GET' });

  const [
    blogControls, setBlogControls,
  ] = useState({ stylePrompt: '' });
  const [
    videoControls, setVideoControls,
  ] = useState({
    stylePrompt: '',
    voiceType: null,
    actorId: null,
    orientation: null,
    voiceover: true,
    music: true,
    videoType: 'voiceover',
    templateId: null,
    templateStyle: null,
    voiceId: null,
  });
  const [
    podcastControls, setPodcastControls,
  ] = useState({
    stylePrompt: '',
    podcastType: 'narrator',
    voiceType: null,
    music: true,
    textData: '',
    podcastStyle: null,
    voiceIds: null,
  });
  const [isGenerating, setIsGenerating] = useState(false);

  useEffect(() => {
    triggerGetFetch();
    if (!getData) return;
    setContentOptions(getData.data);
    setIsLoadingOptions(false);
  }, [getData]); // Empty dependency array ensures this runs only once on mount

  useEffect(() => {
    setPrompt(podcastControls.textData);
  }, [podcastControls.textData]);
  const authContext = useContext(AuthContext);

  const contentType = searchParams.get('type') || 'page';
  const contentTypes = contentType.split(',');

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>,
  ) => {
    let files: File[] = [];

    if (event.type === 'change') {
      files = Array.from((event.target as HTMLInputElement).files || []);
    } else if (event.type === 'drop') {
      files = Array.from((event as React.DragEvent<HTMLDivElement>).dataTransfer.files || []);
    }

    setIsUploading(true);

    const validFiles = files.filter((file) => {
      if (file.size > 100 * 1024 * 1024) {
        alert(`${file.name} is too large. Maximum file size is 100MB.`);
        return false;
      }
      return file.type.startsWith('image/') || file.type.startsWith('video/');
    });

    setSelectedFiles((existingFiles) => [...existingFiles, ...validFiles]);

    const newPreviews = validFiles.map((file) => ({
      src: URL.createObjectURL(file),
      type: file.type.startsWith('image/') ? 'image' : 'video',
    }));
    setMediaPreviews((prev) => [...prev, ...newPreviews]);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    setIsUploading(false);
  };

  const handleRemoveMedia = (index: number) => {
    const updatedPreviews = mediaPreviews.filter((_, i) => i !== index);
    setMediaPreviews(updatedPreviews);

    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    handleFileChange(event);
  };

  const onClick = async () => {
    if (!prompt || prompt.length < 8) return;

    setIsGenerating(true);

    const data: any = {
      prompt,
      content_types: [],
    };

    if (contentOptions.PAGE.content_types.includes('VIDEO') && (contentTypes.includes('page') || contentTypes.includes('video'))) {
      data.content_types.push({
        type: 'VIDEO',
        params: {
          style_prompt: videoControls.stylePrompt,
          video_type: videoControls.videoType,
          voice_type: videoControls.voiceType,
          presenter_id: videoControls.actorId,
          music: videoControls.music,
          orientation: videoControls.orientation,
          template_style: videoControls.templateStyle,
          template_id: videoControls.templateId,
          voice_id: videoControls.voiceId,
          voiceover: videoControls.voiceover,
          device: getCurrentDeviceType(),
        },
      });
    }
    if (contentOptions.PAGE.content_types.includes('PODCAST') && (contentTypes.includes('page') || contentTypes.includes('podcast'))) {
      data.content_types.push({
        type: 'PODCAST',
        params: {
          style_prompt: podcastControls.stylePrompt,
          podcast_type: podcastControls.podcastType,
          voice_type: podcastControls.voiceType,
          music: podcastControls.music,
          podcast_style: podcastControls.podcastStyle,
          voice_ids: podcastControls.voiceIds,
        },
      });
    }
    if (contentOptions.PAGE.content_types.includes('BLOG') && (contentTypes.includes('page') || contentTypes.includes('blog'))) {
      data.content_types.push({
        type: 'BLOG',
        params: {
          style_prompt: blogControls.stylePrompt,
        },
      });
    }
    const token = await authContext.currentUser?.getIdToken();
    const updatedFormData = new FormData();

    selectedFiles.forEach((file) => {
      updatedFormData.append('files', file);
    });

    updatedFormData.append('data', JSON.stringify(data));
    try {
      const postResponse = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/api/v1/pages/`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: updatedFormData,
        },
      ).then((res) => res.json());

      if (postResponse) {
        navigate(`/pages/${postResponse.data.page_id}/`);
      }
    } finally {
      setIsGenerating(false);
    }
  };

  const handleMediaLoad = (src: string) => {
    setLoadingMedia((prev) => {
      const updated = new Set(prev);
      updated.delete(src);
      return updated;
    });
  };

  return (
    <LicaPage>
      {isLoadingOptions ? (
        <div className="flex items-center justify-center h-[calc(100vh-128px)]">
          <Spinner />
        </div>
      ) : (
        <div className={styles.wrapper}>
          <div
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            className={`${styles.container} flex border border-secondary border-opacity-20 flex-col items-center justify-center max-w-none rounded-[3rem] p-4`}
          >
            <Spacer y={3} />
            {contentType !== 'podcast' && (
              <div className={`${styles.yourStoryContainer}`}>
                <textarea
                  value={prompt}
                  className={`${styles.yourStoryInput} bg-transparent w-full resize-none border border-secondary border-opacity-20 p-4 outline-none min-h-36 rounded-2xl`}
                  style={{
                    background: 'transparent',
                  }}
                  placeholder="Enter your story here, paste URL link or drag and drop your images"
                  onChange={(e) => setPrompt(e.target.value)}
                  id="your-story-input"
                />
                <div className="flex relative justify-between items-center w-full mb-2 mt-4">
                  <div className="flex w-full overflow-y-auto mx-4 max-h-20 gap-2">
                    {mediaPreviews.length === 0 ? (
                      isUploading && (
                        <div className="rounded-md flex items-center text-sm text-secondary text-opacity-80">
                          <Spinner color="default" />
                          <span className="ml-4">Media is uploading...</span>
                        </div>
                      )
                    ) : (
                      mediaPreviews.map(({ src, type }, index) => (
                        <div key={index} className="relative">
                          {loadingMedia.has(src) ? (
                            <Spinner color="default" />
                          ) : (
                            <>
                              {type === 'image' ? (
                                <img
                                  src={src}
                                  alt={`Preview ${index}`}
                                  className="max-w-48 max-h-20 rounded-lg"
                                  onLoad={() => handleMediaLoad(src)}
                                />
                              ) : (
                                <video
                                  src={src}
                                  className="max-w-48 max-h-20 rounded-lg"
                                  onLoadedData={() => handleMediaLoad(src)}
                                />
                              )}
                              <button
                                type="button"
                                onClick={() => handleRemoveMedia(index)}
                                className="absolute top-0 right-0 bg-white rounded-full p-0.5"
                              >
                                <Icon icon="carbon:close" style={{ fontSize: '1rem', color: 'black' }} />
                              </button>
                            </>
                          )}
                        </div>
                      ))
                    )}
                  </div>
                  <div className="absolute right-5 -top-16">
                    <label htmlFor="file-upload" className={styles.imageUploadIcon}>
                      <Icon
                        icon="tdesign:attach"
                        className="text-secondary opacity-70"
                        style={{ fontSize: '1.25rem' }}
                      />
                    </label>
                    <input
                      type="file"
                      id="file-upload"
                      className={styles.imageUploadInput}
                      onChange={handleFileChange}
                      multiple
                      ref={fileInputRef}
                      accept=".jpg,.jpeg,.png,.webp,.tiff,.svg,.mp4,.mov,.avi,.mkv"
                    />
                  </div>
                </div>
              </div>
            )}
            {showControl && (
              contentOptions.PAGE.content_types.map((type: string) => {
                if (type === 'BLOG') {
                  if (contentTypes.includes('page') || contentTypes.includes('blog')) {
                    return (
                      <BlogControls
                        key={type}
                        controls={blogControls}
                        setControls={setBlogControls}
                        showTitle={contentTypes.includes('page') || contentTypes.length > 1}
                      />
                    );
                  }
                }
                if (type === 'VIDEO') {
                  if (contentTypes.includes('page') || contentTypes.includes('video')) {
                    return (
                      <VideoControls
                        key={type}
                        controls={videoControls}
                        setControls={setVideoControls}
                        showTitle={contentTypes.includes('page') || contentTypes.length > 1}
                        contentOptions={contentOptions.VIDEO}
                      />
                    );
                  }
                }
                if (type === 'PODCAST') {
                  if (contentTypes.includes('page') || contentTypes.includes('podcast')) {
                    return (
                      <PodcastControls
                        key={type}
                        controls={podcastControls}
                        setControls={setPodcastControls}
                        contentOptions={contentOptions.PODCAST}
                      />
                    );
                  }
                }
                return null;
              })
            )}
            <Spacer y={3} />
            <Button
              radius="full"
              color="primary"
              className="w-36 h-12 mt-5 text-lg font-medium text-primary hover:bg-secondary bg-tertiary"
              onClick={onClick}
              isDisabled={!prompt || prompt.length < 8}
              isLoading={isGenerating}
            >
              Generate
            </Button>
          </div>
        </div>
      )}
    </LicaPage>
  );
}

export default Generate;
