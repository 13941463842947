import React, {
  useState, useRef, useContext, useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Spacer } from '@nextui-org/react';
import { Icon } from '@iconify/react';
import AuthContext from '@/auth/context';
import useFetch from '@/hooks/useFetch';
import LicaPage from '@/components/LicaPage';

import styles from './index.module.css';

function ProductVideo() {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [selectedFile, setSelectedFile] = useState<File | null>(null); // State for a single file
  const [
    videoPreview,
    setVideoPreview,
  ] = useState<string | null>(null); // State for a single image preview
  const [isGenerating, setIsGenerating] = useState(false);

  const {
    data: getData,
    triggerFetch: triggerGetFetch,
  } = useFetch<any>(`${import.meta.env.VITE_BACKEND_URL}/api/v1/content-options/`, { method: 'GET' });

  useEffect(() => {
    triggerGetFetch();
  }, [getData]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null; // Only take the first file
    if (file && file.type.startsWith('video/')) {
      setSelectedFile(file);
      setVideoPreview(URL.createObjectURL(file));
    }
  };

  const handleRemoveImage = () => {
    setSelectedFile(null);
    setVideoPreview(null);
  };

  const handleSubmit = async () => {
    if (!selectedFile) return;

    setIsGenerating(true);

    const data: any = {
      prompt: 'Generate a product video',
      content_types: [{
        type: 'VIDEO',
        params: {
          pipeline: 'PRODUCT_VIDEO',
        },
      }],
    };

    try {
      const formData = new FormData();
      formData.append('data', JSON.stringify(data));
      formData.append('files', selectedFile);

      const token = await authContext.currentUser?.getIdToken();

      const postResponse = await fetch(`${import.meta.env.VITE_BACKEND_URL}/api/v1/pages/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }).then((res) => res.json());

      if (postResponse) {
        navigate(`/pages/${postResponse.data.page_id}/`);
      }
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <LicaPage>
      <div className={styles.wrapper}>
        <div
          className={`${styles.container} flex border border-secondary border-opacity-20 flex-col items-center justify-center max-w-none rounded-[3rem] p-4`}
        >
          <Spacer y={3} />
          <div className={`${styles.yourStoryContainer}`}>
            <div
              className="min-h-36 flex gap-2 items-center justify-center border border-secondary border-opacity-20 p-4 outline-none rounded-2xl cursor-pointer"
              onClick={() => fileInputRef.current?.click()}
            >
              <Icon
                icon="solar:videocamera-add-linear"
                className="text-secondary opacity-70"
                style={{ fontSize: '40px' }}
              />
              <div className="color-[#f3f3f3] font-bold opacity-70">Upload Video</div>
            </div>
            <div>
              <input
                type="file"
                id="file-upload"
                className={styles.imageUploadInput}
                onChange={handleFileChange}
                ref={fileInputRef}
                accept=".mp4"
              />
            </div>
            {videoPreview && (
              <div className={styles.imagePreviewContainer}>
                <div className="relative">
                  <video
                    src={videoPreview}
                    className={styles.imagePreview}
                  />
                  <button
                    type="button"
                    onClick={handleRemoveImage}
                    className={styles.removeButton}
                  >
                    <Icon icon="carbon:close" style={{ fontSize: '1rem', color: 'black' }} />
                  </button>
                </div>
              </div>
            )}
          </div>
          <Spacer y={3} />
          <Button
            radius="full"
            color="primary"
            className="w-36 h-12 mt-5 text-lg font-medium text-primary hover:bg-secondary bg-tertiary"
            onClick={handleSubmit}
            isDisabled={!selectedFile}
            isLoading={isGenerating}
          >
            Generate
          </Button>
        </div>
      </div>
    </LicaPage>
  );
}

export default ProductVideo;
