import React, {
  forwardRef, useMemo, useRef,
} from 'react';
import { Img } from 'remotion';
import { CSSProperties } from 'styled-components';
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';

import { AnimationFactory } from '../animation/animation';
import { useGsapTimeline } from '../animation/gsap';
import Text from './text';
import Video from './video';
import Image from './image';

gsap.registerPlugin(SplitText);

interface ContentItem {
  items: any;
  itemStyles: any;
  itemAnimations: any;
}

interface ListTextProps {
  data: ContentItem[];
  style: CSSProperties;
  slideDuration: number
}

const useItemAnimations = (
  titleRef: any,
  subtitleRef: any,
  lineRef: any,
  imageContainer: any,
  animations: any,
  duration: number,
  delay: number,
) => {
  useGsapTimeline(() => {
    const animationFactory = AnimationFactory.createAnimation(animations.title.type);
    const { gsapTimeline } = animationFactory.createTimeline(titleRef.current, {
      ...animations.title.attributes,
      delay,
      duration,
    });
    return gsapTimeline;
  });

  useGsapTimeline(() => {
    const animationFactory = AnimationFactory.createAnimation(animations.subtitle.type);
    const { gsapTimeline } = animationFactory.createTimeline(subtitleRef.current, {
      ...animations.subtitle.attributes,
      delay: delay + 0.3,
      duration,
    });
    return gsapTimeline;
  });

  useGsapTimeline(() => {
    const animationFactory = AnimationFactory.createAnimation(animations.line?.type);
    const { gsapTimeline } = animationFactory.createTimeline(lineRef.current, {
      ...animations.line?.attributes,
      delay,
      duration,
    });

    return gsapTimeline;
  });

  useGsapTimeline(() => {
    const animationFactory = AnimationFactory.createAnimation('wipe');
    const { gsapTimeline } = animationFactory.createTimeline(imageContainer.current, {
      direction: 'down',
      duration,
      delay,
      opacity: 1,
    });

    return gsapTimeline;
  });
};

function AnimatedItem({
  item, delay, style, animation, duration, length, mainHeight,
}: any) {
  const titleRef = useRef<HTMLDivElement>(null);
  const subtitleRef = useRef<HTMLDivElement>(null);
  const lineRef = useRef<HTMLDivElement>(null);
  const imageContainer = useRef<HTMLDivElement>(null);

  useItemAnimations(
    titleRef,
    subtitleRef,
    lineRef,
    imageContainer,
    animation.animations,
    duration,
    delay,
  );
  const imageRef = useRef<any>(null);

  return (
    <div className="flex items-center h-full" style={{ height: `calc(${mainHeight} / ${length})` }}>
      <div
        ref={imageContainer}
        className="w-1/3 h-full flex overflow-hidden"
      >
        {item.image_keyword.includes('.mp4') || item.image_keyword.includes('.mov') ? (
          <Video
            src={item.image_keyword}
            className="object-cover"
            style={{
              height: `calc(${mainHeight} / ${length})`, width: '100%', position: 'relative', ...style.imageStyle,
            }}
            volume={0}
            loop
          />
        ) : (
          <Image
            ref={imageRef}
            src={item.image_keyword}
            slideDuration={(duration * length) + 1}
            style={{ height: `calc(${mainHeight} / ${length})`, width: '100%', ...style.imageStyle }}
          />
        )}
      </div>
      <div className="w-2/3 h-full right-10 relative z-20 flex items-center gap-3">
        <div
          ref={lineRef}
          style={{
            width: '35px', height: '3px', backgroundColor: 'white', marginBottom: '12px', ...style.lineStyle,
          }}
        />
        <div>
          <div className="overflow-hidden">
            <Text ref={titleRef} text={item.title} style={{ fontSize: '20px', color: 'white', ...style.titleStyle }} />
          </div>
          <div className="overflow-hidden">
            <Text ref={subtitleRef} text={item.subtitle} style={{ color: 'white', ...style.subtitleStyle }} />
          </div>
        </div>
      </div>
    </div>
  );
}

const ImageMinimalListicle = forwardRef<HTMLDivElement, ListTextProps>(
  (
    { data, style, slideDuration },
    ref,
  ) => {
    const { items, itemStyles, itemAnimations } = data[0];
    const singularDuration = slideDuration / items.length;
    const delays = useMemo(() => {
      let currentDelay = 0;
      return items.map(() => {
        const delayForThisItem = currentDelay;
        currentDelay += singularDuration;
        return delayForThisItem;
      });
    }, [data]);

    return (
      <div style={style} ref={ref}>
        {items.map((item: any, index: any) => (
          <AnimatedItem
            key={index}
            item={item}
            duration={singularDuration}
            mainHeight={style.height}
            length={items.length}
            background={style.background}
            animation={itemAnimations[0]}
            style={itemStyles[0]}
            delay={delays[index]}
          />
        ))}
      </div>
    );
  },
);

ImageMinimalListicle.displayName = 'ImageMinimalListicle';

export default ImageMinimalListicle;
