import { useEffect, useState } from 'react';
import { Spinner } from '@nextui-org/react';
import { useLocation, useParams } from 'react-router-dom';

import LicaPage from '@/components/LicaPage';
import useFetch from '@/hooks/useFetch';

import ShareButton from '../components/ShareButton';

import { EditBlog } from './edit-blog';
import { EditPodcastChatNew } from './edit-podcast-chat-new';
import { EditPodcastNew } from './edit-podcast-new';
import { EditVideoTogether } from './edit-video-together';

interface ContentRequestState {
  loading: boolean;
  loaded: boolean;
  loadingFailed: boolean;
  data: any;
}

function Content({
  contentType, data, contentId, pageId, responseData,
}: { contentType: any; data: any; contentId: string; pageId: string; responseData: any }) {
  const location = useLocation();

  switch (contentType) {
    case 'VIDEO':
      if (responseData.output.version === 'v3') {
        return (
          <EditVideoTogether
            videoId={contentId}
            responseData={responseData}
          />
        );
      }

      return null;

    case 'BLOGPOST':
      return (
        <EditBlog
          data={data}
          title={data.title}
          blogId={contentId}
          pageId={pageId}
          responseData={responseData}
        />
      );
    case 'PODCAST':
      if (responseData.output.version === 'v2') {
        if (location.pathname.includes('chat')) {
          return (
            <EditPodcastChatNew
              data={data}
              title={data.title}
              podcastId={contentId}
              pageId={pageId}
              responseData={responseData}
            />
          );
        }
        return (
          <EditPodcastNew
            data={data}
            title={data.title}
            podcastId={contentId}
            pageId={pageId}
            responseData={responseData}
          />
        );
      }

      return null;
    default:
      return null;
  }
}

export default function EditContent() {
  const { contentId } = useParams();
  const [contentRequest, setContentRequest] = useState<ContentRequestState>({
    loading: true,
    loaded: false,
    loadingFailed: false,
    data: null,
  });

  const { fetchData: getContent } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${contentId}/`,
    { method: 'GET' },
  );

  const fetchContent = async () => {
    try {
      const responseData = await getContent();

      if (responseData?.data?.status === 'completed') {
        setContentRequest((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
          data: responseData.data,
        }));
      } else if (responseData?.data?.status === 'failed') {
        setContentRequest((prevState) => ({
          ...prevState,
          loading: false,
          loaded: false,
          loadingFailed: true,
          data: null,
        }));
      } else {
        setTimeout(() => {
          fetchContent();
        }, 10000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (contentRequest.loading && !contentRequest.data) {
      fetchContent().then(() => {
      });
    }
  }, [contentRequest]);

  const pageButtons = [
    <ShareButton url={`${window.origin}/pages/${contentRequest.data?.page_id}`} key={1} />,
  ];

  return (
    <LicaPage buttons={pageButtons}>
      {contentRequest.loaded ? (
        <Content
          contentType={contentRequest.data.content_type}
          data={contentRequest.data.output}
          contentId={`${contentId}`}
          pageId={contentRequest.data.page_id}
          responseData={contentRequest.data}
        />
      ) : (!contentRequest.loading && contentRequest.loadingFailed) ? (
        <div className="flex items-center justify-center h-full bg-gray-100" style={{ minHeight: 'inherit' }}>
          <div style={{ color: '#B6B6B6' }}>
            Couldn't load the content. Please try again.
          </div>
        </div>
      ) : (
        <div
          className="flex flex-col gap-5 text-secondary items-center justify-center h-full"
          style={{ minHeight: 'inherit' }}
        >
          <Spinner color="white" size="lg" />
          Loading Content...
        </div>
      )}
    </LicaPage>
  );
}
