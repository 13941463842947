import { continueRender, delayRender, staticFile } from 'remotion';

import { loadFont as loadFont1 } from '@remotion/google-fonts/DMMono';
import { loadFont as loadFont2 } from '@remotion/google-fonts/Poppins';
import { loadFont as loadFont3 } from '@remotion/google-fonts/Roboto';
import { loadFont as loadFont4 } from '@remotion/google-fonts/Lato';
import { loadFont as loadFont5 } from '@remotion/google-fonts/OpenSans';
import { loadFont as loadFont6 } from '@remotion/google-fonts/Montserrat';
import { loadFont as loadFont7 } from '@remotion/google-fonts/Merriweather';
import { loadFont as loadFont8 } from '@remotion/google-fonts/SourceSans3';
import { loadFont as loadFont9 } from '@remotion/google-fonts/WorkSans';
import { loadFont as loadFont10 } from '@remotion/google-fonts/Karla';
import { loadFont as loadFont11 } from '@remotion/google-fonts/Inconsolata';
import { loadFont as loadFont12 } from '@remotion/google-fonts/PlayfairDisplay';
import { loadFont as loadFont13 } from '@remotion/google-fonts/CormorantGaramond';
import { loadFont as loadFont14 } from '@remotion/google-fonts/ProzaLibre';
import { loadFont as loadFont15 } from '@remotion/google-fonts/NunitoSans';
import { loadFont as loadFont16 } from '@remotion/google-fonts/JetBrainsMono';
import { loadFont as loadFont17 } from '@remotion/google-fonts/Inter';
import { loadFont as loadFont18 } from '@remotion/google-fonts/RobotoFlex';
import { loadFont as loadFont19 } from '@remotion/google-fonts/DMSans';
import { loadFont as loadFont20 } from '@remotion/google-fonts/Lexend';
import { loadFont as loadFont21 } from '@remotion/google-fonts/Abel';
import { loadFont as loadFont22 } from '@remotion/google-fonts/BricolageGrotesque';
import { loadFont as loadFont23 } from '@remotion/google-fonts/AnekLatin';
import { loadFont as loadFont24 } from '@remotion/google-fonts/Urbanist';
import { loadFont as loadFont25 } from '@remotion/google-fonts/ArchivoNarrow';

export const loadFonts = () => {
  loadFont1();
  loadFont2();
  loadFont3();
  loadFont4();
  loadFont5();
  loadFont6();
  loadFont7();
  loadFont8();
  loadFont9();
  loadFont10();
  loadFont11();
  loadFont12();
  loadFont13();
  loadFont14();
  loadFont15();
  loadFont16();
  loadFont17();
  loadFont18();
  loadFont19();
  loadFont20();
  loadFont21();
  loadFont22();
  loadFont23();
  loadFont24();
  loadFont25();

  const waitForFont = delayRender();

  const hafferFont = new FontFace('Haffer', `url('${staticFile('fonts/HafferVF.ttf')}') format('truetype')`);
  hafferFont.load().then(() => {
    document.fonts.add(hafferFont);
    continueRender(waitForFont);
  }).catch((err) => console.log('Error loading font', err));

  const geistBlackFont = new FontFace('Geist Black', `url('${staticFile('fonts/Geist-Black.otf')}') format('opentype')`);
  geistBlackFont.load().then(() => {
    document.fonts.add(geistBlackFont);
    continueRender(waitForFont);
  }).catch((err) => console.log('Error loading font', err));

  const geistBoldFont = new FontFace('Geist Bold', `url('${staticFile('fonts/Geist-Bold.otf')}') format('opentype')`);
  geistBoldFont.load().then(() => {
    document.fonts.add(geistBoldFont);
    continueRender(waitForFont);
  }).catch((err) => console.log('Error loading font', err));

  const geistLightFont = new FontFace('Geist Light', `url('${staticFile('fonts/Geist-Light.otf')}') format('opentype')`);
  geistLightFont.load().then(() => {
    document.fonts.add(geistLightFont);
    continueRender(waitForFont);
  }).catch((err) => console.log('Error loading font', err));

  const geistMediumFont = new FontFace('Geist Medium', `url('${staticFile('fonts/Geist-Medium.otf')}') format('opentype')`);
  geistMediumFont.load().then(() => {
    document.fonts.add(geistMediumFont);
    continueRender(waitForFont);
  }).catch((err) => console.log('Error loading font', err));

  const geistRegularFont = new FontFace('Geist Regular', `url('${staticFile('fonts/Geist-Regular.otf')}') format('opentype')`);
  geistRegularFont.load().then(() => {
    document.fonts.add(geistRegularFont);
    continueRender(waitForFont);
  }).catch((err) => console.log('Error loading font', err));

  const geistSemiBoldFont = new FontFace('Geist SemiBold', `url('${staticFile('fonts/Geist-SemiBold.otf')}') format('opentype')`);
  geistSemiBoldFont.load().then(() => {
    document.fonts.add(geistSemiBoldFont);
    continueRender(waitForFont);
  }).catch((err) => console.log('Error loading font', err));

  const geistThinFont = new FontFace('Geist Thin', `url('${staticFile('fonts/Geist-Thin.otf')}') format('opentype')`);
  geistThinFont.load().then(() => {
    document.fonts.add(geistThinFont);
    continueRender(waitForFont);
  }).catch((err) => console.log('Error loading font', err));

  const geistUltraBlackFont = new FontFace('Geist UltraBlack', `url('${staticFile('fonts/Geist-UltraBlack.otf')}') format('opentype')`);
  geistUltraBlackFont.load().then(() => {
    document.fonts.add(geistUltraBlackFont);
    continueRender(waitForFont);
  }).catch((err) => console.log('Error loading font', err));

  const geistUltraLightFont = new FontFace('Geist UltraLight', `url('${staticFile('fonts/Geist-UltraLight.otf')}') format('opentype')`);
  geistUltraLightFont.load().then(() => {
    document.fonts.add(geistUltraLightFont);
    continueRender(waitForFont);
  }).catch((err) => console.log('Error loading font', err));
};
