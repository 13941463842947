import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  continueRender,
  delayRender,
  useCurrentFrame,
  useVideoConfig,
} from 'remotion';
import parseSRT from 'parse-srt';

import { Word } from './word';

const useWindowedFrameSubs = (
  src: string,
  options: { windowStart: number; windowEnd: number },
) => {
  const { windowStart, windowEnd } = options;
  const config = useVideoConfig();
  const { fps } = config;

  const parsed = useMemo(() => parseSRT(src), [src]);

  return useMemo(() => parsed
    .map((item: any) => {
      const start = Math.floor(item.start * fps);
      const end = Math.floor(item.end * fps);

      return { item, start, end };
    })
    .filter(({ start }: any) => start >= windowStart && start <= windowEnd)
    .map(
      ({ item, start, end }: { item: any; start: number; end: number }) => ({
        ...item,
        start,
        end,
      }),
    ), [fps, parsed, windowEnd, windowStart]);
};

interface Props {
  subtitlesFileName: string;
  linesPerPage: number;
  subtitlesTextColor: string;
  subtitlesBackgroundColor: string;
  subtitlesActiveTextColor: string;
  subtitlesZoomMeasurerSize: number;
  subtitlesLineHeight: number;
  audioOffsetInSeconds: number;
  ref?: any;
  textStyle?: any;
  style?: object;
  wrapperStyle?: any
}

function PaginatedSubtitles({
  ref,
  subtitlesFileName,
  linesPerPage,
  subtitlesTextColor,
  subtitlesActiveTextColor,
  subtitlesBackgroundColor,
  subtitlesLineHeight,
  audioOffsetInSeconds,
  subtitlesZoomMeasurerSize,
  textStyle,
  style,
  wrapperStyle,
}: Props) {
  const config = useVideoConfig();
  const { fps, durationInFrames } = config;
  const frame = useCurrentFrame();
  const windowRef = useRef<HTMLDivElement>(null);
  const zoomMeasurer = useRef<HTMLDivElement>(null);
  const [subtitles, setSubtitles] = useState('');
  const [handle] = useState(() => delayRender());
  const [fontHandle] = useState(() => delayRender());
  const [fontLoaded, setFontLoaded] = useState(false);
  const [activeWord, setActiveWord] = useState('');
  const audioOffsetInFrames = Math.round(audioOffsetInSeconds * fps);

  const windowedFrameSubs = useWindowedFrameSubs(subtitles, {
    windowStart: audioOffsetInFrames,
    windowEnd: audioOffsetInFrames + durationInFrames,
  });

  const [lineOffset, setLineOffset] = useState(0);

  const [
    linesPerPageShown,
    setLinesPerPageShown,
  ] = useState({ previous: 0, current: linesPerPage });
  const [
    currentWords,
    setCurrentWords,
  ] = useState(windowedFrameSubs.slice(0, linesPerPageShown.current));

  useEffect(() => {
    continueRender(fontHandle);
    setFontLoaded(true);
  }, [fontHandle, fontLoaded]);

  useEffect(() => {
    if (!fontLoaded) {
      return;
    }
    const zoom = (zoomMeasurer.current?.getBoundingClientRect().height as number)
      / subtitlesZoomMeasurerSize;
    const linesRendered = (windowRef.current?.getBoundingClientRect().height as number)
      / (subtitlesLineHeight * zoom);
    const linesToOffset = Math.max(0, linesRendered - linesPerPage);
    setLineOffset(linesToOffset);
    continueRender(handle);
  }, [
    fontLoaded,
    frame,
    handle,
    linesPerPage,
    subtitlesLineHeight,
    subtitlesZoomMeasurerSize,
  ]);

  useEffect(() => {
    setCurrentWords(windowedFrameSubs.slice(linesPerPageShown.previous, linesPerPageShown.current));
  }, [windowedFrameSubs, linesPerPageShown.previous, linesPerPageShown.current]);

  useEffect(() => {
    const firstWordRenderedCurrent = currentWords.slice(0)[0];
    const lastWordRenderedCurrent = currentWords.slice(-1)[0];

    // If some words not loaded or somehow podcast is longer than data, this prevents to stuck
    const lastWordExists = windowedFrameSubs.slice(-1)[0];

    // If some words not loaded or somehow podcast is longer than data, this prevents to stuck
    const firstWordExists = windowedFrameSubs.slice(0)[0];

    const nextWord = windowedFrameSubs[
      windowedFrameSubs.findIndex((word: any) => word.id === lastWordRenderedCurrent?.id) + 1
    ];

    if (frame === 0) {
      setLinesPerPageShown(() => ({
        previous: 0,
        current: linesPerPage,
      }));
    } else if (
      lastWordExists && lastWordExists.end > frame
      && lastWordRenderedCurrent
      && lastWordRenderedCurrent.end < frame
      && nextWord && nextWord.start < frame
    ) {
      setLinesPerPageShown((prevState) => ({
        previous: prevState.current,
        current: Math.min(prevState.current + linesPerPage, windowedFrameSubs.length),
      }));
    } else if (
      linesPerPageShown.previous > 0
      && firstWordExists !== firstWordRenderedCurrent
      && firstWordRenderedCurrent
      && firstWordRenderedCurrent.start > frame
    ) {
      setLinesPerPageShown((prevState) => ({
        previous: prevState.current - (linesPerPage * 2),
        current: prevState.current - linesPerPage,
      }));
    }
  }, [frame, currentWords]);

  useEffect(() => {
    const currentWord = windowedFrameSubs.find((word: any) => word.start === frame);
    if (currentWord) {
      setActiveWord(currentWord.id);
    }
  }, [frame, windowedFrameSubs]);

  useEffect(() => {
    fetch(subtitlesFileName)
      .then((res) => res.text())
      .then((text: any) => {
        setSubtitles(text);
        continueRender(handle);
      })
      .catch((err) => {
        console.log('Error fetching subtitles', err);
      });
  }, [handle, subtitlesFileName]);

  const lastWordRendered = currentWords.slice(-1);

  const subtitlesEnd = frame === 0 || (windowedFrameSubs.length > 0 && frame <= windowedFrameSubs.slice(-1)[0].end);

  return (
    <div
      ref={ref}
      style={{
        lineHeight: `${subtitlesLineHeight}px`,
        ...style,
      }}
      className="captions"
    >
      {subtitlesEnd && (
        <div className="relative">
          <div
            ref={windowRef}
            className="rounded-full p-4 min-h-20 flex items-center justify-center"
            style={{
              backgroundColor: subtitlesBackgroundColor,
              color: subtitlesTextColor,
              ...wrapperStyle,
            }}
          >
            <div>
              {currentWords.length > 0 && '"'}
              {currentWords.map((item: any, index: number) => (
                <span className={textStyle} key={item.id} id={String(item.id)}>
                  <Word
                    frame={lastWordRendered.end * 2}
                    item={item}
                    transcriptionColor={
                      item.id === activeWord ? subtitlesActiveTextColor : subtitlesTextColor
                    }
                    isActive={item.id === activeWord}
                  />
                  {index !== currentWords.length - 1 ? ' ' : ''}
                </span>
              ))}
              {currentWords.length > 0 && '"'}
            </div>
          </div>
          <div
            ref={zoomMeasurer}
            style={{
              height: subtitlesZoomMeasurerSize,
              width: subtitlesZoomMeasurerSize,
            }}
          />
        </div>
      )}
    </div>
  );
}

export default PaginatedSubtitles;
