import React, { Fragment, useState } from 'react';
import { Button } from '@nextui-org/react';
import { Icon } from '@iconify/react';
import cloneDeep from 'lodash/cloneDeep';

import useFetch from '@/hooks/useFetch';

import EditAudioModal from './edit-audio-modal';
import EditSlideItem from './edit-slide-item';

const AudioType = {
  Music: 'MUSIC',
  VoiceOver: 'VOICEOVER',
};

function AddNewSlide(
  {
    index, updateVideo, fetchContent, isProcessing, setIsProcessing,
    slidesCount, setAddNewSlideIndex,
  }: any,
) {
  const [prompt, setPrompt] = useState('');

  const handleVideoUpdate = async () => {
    if (isProcessing) return;

    setIsProcessing(true);

    let updatedPrompt = `ADD a NEW slide between slide ${index + 1} and slide ${index + 2} with the following instructions - ${prompt}`;
    if (index === slidesCount - 1) {
      updatedPrompt = `ADD a NEW slide after slide ${index + 1} with the following instructions - ${prompt}`;
    }

    const payload = {
      prompt: updatedPrompt,
    };

    await updateVideo({
      body: JSON.stringify(payload),
    });

    await fetchContent(true);
  };

  return (
    <div className="border-1 border-solid border-[#eaecf049] p-4 rounded-2xl my-8">
      <textarea
        placeholder="Add details about the scene you want to add"
        className="p-1 border-1 rounded-[4px] outline-none border-[#eaecf049] mb-2 w-full bg-transparent h-20 resize-none"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
      />
      <div className="flex justify-end gap-2">
        <Button
          radius="full"
          size="md"
          className="text-primary"
          onClick={() => { setAddNewSlideIndex(null); }}
        >
          Cancel
        </Button>
        <Button
          radius="full"
          size="md"
          className="text-primary !bg-tertiary"
          onClick={handleVideoUpdate}
          isLoading={isProcessing}
          isDisabled={prompt.trim() === ''}
        >
          {isProcessing ? 'Generating' : 'Generate'}
        </Button>
      </div>
    </div>
  );
}

function SlideItems(
  {
    videoId, internalVideoData, setInternalVideoData, regenerate,
    setIsProcessing, isProcessing, responseData,
    addNewSlideIndex, setAddNewSlideIndex, editSlideIndex, setEditSlideIndex,
  }: {
    videoId: string;
    internalVideoData: any;
    responseData: any;
    setInternalVideoData: (data: any) => void;
    setIsProcessing: (processing: boolean) => void;
    regenerate: (value: boolean) => void;
    isProcessing: boolean;
    addNewSlideIndex: number | null;
    setAddNewSlideIndex: (index: number | null) => void;
    editSlideIndex: number | null;
    setEditSlideIndex: (index: number | null) => void;
  },
) {
  const [openEditAudioModal, setOpenEditAudioModal] = useState(false);

  const { fetchData: updateVideo } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${videoId}/update`,
    { method: 'POST' },
  );

  const handleSaveClick = async () => {
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);

    const data = {
      screenplay_slides: internalVideoData.screenplay_slides,
      unhydrated_slides: internalVideoData.unhydrated_slides,
      subtitles_enabled: internalVideoData.subtitles_enabled,
      bg_music: internalVideoData.bg_music,
    };

    try {
      const saveResponse = await updateVideo({
        body: JSON.stringify(data),
      });

      if (['initiated', 'processing'].includes(saveResponse.data.update_status)) {
        regenerate(true);
      } else {
        setIsProcessing(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDuplicateSlide = (index: number) => {
    if (index >= internalVideoData.video_data.slides.length) {
      setInternalVideoData({
        ...internalVideoData,
        video_data: {
          ...internalVideoData.video_data,
          slides: [
            ...internalVideoData.video_data.slides,
            {
              ...internalVideoData.video_data.slides[-1],
            },
          ],
        },
        screenplay_slides: [
          ...internalVideoData.screenplay_slides,
          {
            ...internalVideoData.screenplay_slides[-1],
          },
        ],
        unhydrated_slides: [
          ...internalVideoData.unhydrated_slides,
          {
            ...internalVideoData.unhydrated_slides[-1],
          },
        ],
        presenter_data: {
          ...internalVideoData.presenter_data,
          slide_durations: [
            ...internalVideoData.presenter_data.slide_durations,
            internalVideoData.presenter_data.slide_durations[-1],
          ],
        },
      });
    } else {
      setInternalVideoData({
        ...internalVideoData,
        video_data: {
          ...internalVideoData.video_data,
          slides: [
            ...internalVideoData.video_data.slides.slice(0, index + 1),
            {
              ...internalVideoData.video_data.slides[index],
            },
            ...internalVideoData.video_data.slides.slice(index + 1),
          ],
        },
        screenplay_slides: [
          ...internalVideoData.screenplay_slides.slice(0, index + 1),
          {
            ...internalVideoData.screenplay_slides[index],
          },
          ...internalVideoData.screenplay_slides.slice(index + 1),
        ],
        unhydrated_slides: [
          ...internalVideoData.unhydrated_slides.slice(0, index + 1),
          {
            ...internalVideoData.unhydrated_slides[index],
          },
          ...internalVideoData.unhydrated_slides.slice(index + 1),
        ],
        presenter_data: {
          ...internalVideoData.presenter_data,
          slide_durations: [
            ...internalVideoData.presenter_data.slide_durations.slice(0, index + 1),
            internalVideoData.presenter_data.slide_durations[index],
            ...internalVideoData.presenter_data.slide_durations.slice(index + 1),
          ],
        },
      });
    }
  };

  const handleDeleteSlide = (index: number) => {
    setInternalVideoData({
      ...internalVideoData,
      video_data: {
        ...internalVideoData.video_data,
        slides: [
          ...internalVideoData.video_data.slides.slice(0, index),
          ...internalVideoData.video_data.slides.slice(index + 1),
        ],
      },
      screenplay_slides: [
        ...internalVideoData.screenplay_slides.slice(0, index),
        ...internalVideoData.screenplay_slides.slice(index + 1),
      ],
      unhydrated_slides: [
        ...internalVideoData.unhydrated_slides.slice(0, index),
        ...internalVideoData.unhydrated_slides.slice(index + 1),
      ],
    });
  };

  const handleSaveSlide = (slide: any, updatedScreenplaySlide: any, index: number) => {
    const cloneSlides = [...internalVideoData.video_data.slides];
    const cloneSlide = { ...cloneSlides[index] };
    cloneSlides[index] = {
      ...cloneSlide,
      ...slide,
    };

    setInternalVideoData((currentVal: any) => ({
      ...currentVal,
      video_data: {
        ...currentVal.video_data,
        slides: cloneSlides,
      },
      screenplay_slides: [
        ...currentVal.screenplay_slides.slice(0, index),
        updatedScreenplaySlide,
        ...currentVal.screenplay_slides.slice(index + 1),
      ],
    }));
  };

  const handleSaveAudio = (audios: any) => {
    const bgMusicAudio = audios.filter((audio: any) => audio.type === 'MUSIC')[0].src;

    setInternalVideoData({
      ...internalVideoData,
      video_data: {
        ...internalVideoData.video_data,
        audios,
      },
      bg_music: {
        ...internalVideoData.bg_music,
        src: bgMusicAudio,
      },
    });
  };

  const onSubtitleToggle = () => {
    let updatedComponents;
    if (internalVideoData.subtitles_enabled) {
      updatedComponents = internalVideoData.video_data.components.filter((component: any) => component.type !== 'SUBTITLES');
    } else {
      updatedComponents = cloneDeep(responseData.output.video_data.components);
    }

    setInternalVideoData({
      ...internalVideoData,
      subtitles_enabled: !internalVideoData.subtitles_enabled,
      video_data: {
        ...internalVideoData.video_data,
        components: updatedComponents,
      },
    });
  };

  return (
    <div className="overflow-y-auto h-full minimal-scrollbar px-4">
      <EditAudioModal
        audios={internalVideoData.video_data.audios}
        onClose={() => setOpenEditAudioModal(false)}
        onSave={handleSaveAudio}
        isOpen={openEditAudioModal}
        allowTypes={[AudioType.Music]}
      />
      <div className="flex justify-between">
        <div>
          <Button
            onClick={() => setOpenEditAudioModal(true)}
            color="primary"
            className="text-white"
            isIconOnly
            style={{
              borderRadius: '50%',
              padding: '0',
            }}
          >
            <Icon icon="iconoir:tiktok" style={{ fontSize: '1.5rem' }} />
          </Button>
          <Button
            onClick={() => onSubtitleToggle()}
            color="primary"
            className="text-white"
            isIconOnly
            style={{
              borderRadius: '50%',
              padding: '0',
            }}
          >
            <Icon
              icon={internalVideoData.subtitles_enabled ? 'iconoir:closed-captions-tag-solid' : 'iconoir:closed-captions-tag'}
              style={{ fontSize: '1.5rem' }}
            />
          </Button>
        </div>
        <Button
          radius="full"
          size="md"
          className="text-primary !bg-tertiary mb-4 ml-8"
          onClick={handleSaveClick}
          startContent={(isProcessing) ? null : (
            <Icon
              icon="material-symbols:check-circle-outline"
              style={{ fontSize: '1.5rem' }}
            />
          )}
          isLoading={(isProcessing)}
        >
          {(isProcessing) ? 'Updating' : 'Save Changes'}
        </Button>
      </div>
      {internalVideoData.video_data.slides.map((slide: any, index: number) => (
        <Fragment key={`${slide.duration}-${index}`}>
          <EditSlideItem
            slide={slide}
            index={index}
            onDelete={handleDeleteSlide}
            onDuplicateSlide={handleDuplicateSlide}
            onSave={handleSaveSlide}
            unhydratedSlideLayout={internalVideoData.unhydrated_slides[index]}
            screenplaySlide={internalVideoData.screenplay_slides[index]}
            showSlideEditingModal={editSlideIndex === index}
            setEditSlideIndex={setEditSlideIndex}
            isProcessing={isProcessing}
            setIsProcessing={setIsProcessing}
            updateVideo={updateVideo}
            fetchContent={regenerate}
          />
          {index !== addNewSlideIndex ? (
            <div className="flex justify-center items-center w-full opacity-10 hover:opacity-100 transition-opacity">
              <Button
                size="sm"
                isIconOnly
                className="rounded-full"
                onClick={() => {
                  setAddNewSlideIndex(index);
                }}
              >
                <Icon
                  icon="fa-solid:plus"
                  style={{ fontSize: '1rem' }}
                />
              </Button>
            </div>
          ) : null}
          {index === addNewSlideIndex ? (
            <AddNewSlide
              index={index}
              slidesCount={internalVideoData.video_data.slides.length}
              updateVideo={updateVideo}
              fetchContent={regenerate}
              isProcessing={isProcessing}
              setIsProcessing={setIsProcessing}
              setAddNewSlideIndex={setAddNewSlideIndex}
            />
          ) : null}
        </Fragment>
      ))}
      {internalVideoData.video_data.slides.length === 0 && addNewSlideIndex === null ? (
        <div className="flex justify-center items-center w-full opacity-10 hover:opacity-100 transition-opacity">
          <Button
            size="sm"
            isIconOnly
            className="rounded-full"
            onClick={() => {
              setAddNewSlideIndex(0);
            }}
          >
            <Icon
              icon="fa-solid:plus"
              style={{ fontSize: '1rem' }}
            />
          </Button>
        </div>
      ) : null}
      {addNewSlideIndex === 0 ? (
        <AddNewSlide
          index={0}
          slidesCount={0}
          updateVideo={updateVideo}
          fetchContent={regenerate}
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
          setAddNewSlideIndex={setAddNewSlideIndex}
        />
      ) : null}
    </div>
  );
}

export default SlideItems;
