import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@nextui-org/react';

import useFetch from '@/hooks/useFetch';
import RemotionPlayer from '@/remotion/RemotionPlayer';
import { downloadContent } from '@/services/content';

function RemotionPlayerContainer(
  {
    videoId,
    internalVideoData,
    addNewSlideIndex,
    setAddNewSlideIndex,
    setInternalVideoData,
    setIsProcessing,
    regenerate,
    responseData,
    editSlideIndex,
    setEditSlideIndex,
    setChatMessage,
  }: {
    videoId: string;
    internalVideoData: any;
    responseData: any;
    setInternalVideoData: (data: any) => void;
    setIsProcessing: (val: boolean) => void;
    regenerate: boolean;
    addNewSlideIndex: number | null;
    setAddNewSlideIndex: (index: number | null) => void;
    editSlideIndex: number | null;
    setEditSlideIndex: (index: number | null) => void;
    setChatMessage: (message: string) => void;
  },
) {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const navigate = useNavigate();

  const { fetchData: getContent } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${videoId}/`,
    { method: 'GET' },
  );

  const fetchContent = async () => {
    try {
      const contentResponseData = await getContent();

      if (contentResponseData?.data?.update_status === 'completed') {
        setInternalVideoData(contentResponseData.data.output);
        setIsProcessing(false);
        setChatMessage('The video is updated! Is there anything else you would like to change?');

        if (addNewSlideIndex !== null) {
          setAddNewSlideIndex(null);
        }
        if (editSlideIndex !== null) {
          setEditSlideIndex(null);
        }
      } else if (contentResponseData?.data?.update_status === 'failed' || !contentResponseData?.data?.update_status) {
        setIsProcessing(false);
        setChatMessage("I'm facing some issues while making the changes. Could you please try again?");

        if (addNewSlideIndex !== null) {
          setAddNewSlideIndex(null);
        }
        if (editSlideIndex !== null) {
          setEditSlideIndex(null);
        }
      } else if (['initiated', 'processing'].includes(contentResponseData?.data?.update_status)) {
        setTimeout(() => {
          fetchContent();
        }, 5000);
      }
    } catch (err) { /* empty */ }
  };

  useEffect(() => {
    if (regenerate) {
      fetchContent();
    }
  }, [regenerate]);

  useEffect(() => {
    if (['initiated', 'processing'].includes(responseData?.update_status)) {
      setIsProcessing(true);

      setTimeout(() => {
        fetchContent();
      }, 5000);
    }
  }, []);

  const handleDownloadClick = async () => {
    if (isDownloading) {
      return;
    }

    setIsDownloading(true);
    await downloadContent(internalVideoData.video_data);
    setIsDownloading(false);
  };

  const handleCancel = () => {
    navigate(`/pages/${responseData.page_id}`);
  };

  return (
    <div className="h-full grow flex flex-col gap-2">
      <div
        className="text-secondary"
        style={{
          fontSize: '1.5rem',
          fontWeight: 800,
        }}
      >
        {responseData.output.title}
      </div>
      <div className="flex h-full flex-col flex-auto items-center w-full gap-5">
        <div
          style={{
            aspectRatio: internalVideoData?.video_data?.width
              / internalVideoData?.video_data?.height || 16 / 9,
          }}
          className="rounded-xl flex-auto max-w-full max-h-full"
        >
          {internalVideoData?.video_data && (
            <RemotionPlayer
              data={internalVideoData.video_data}
              clickToPlay={false}
            />
          )}
        </div>
        <div className="flex gap-4">
          <Button
            radius="full"
            size="md"
            className="text-primary !bg-tertiary"
            onClick={handleDownloadClick}
            isLoading={isDownloading}
          >
            Download video
          </Button>
          <Button
            radius="full"
            size="md"
            className="text-primary hover:!bg-tertiary duration-400 !bg-secondary"
            onClick={handleCancel}
          >
            Back
          </Button>
        </div>
      </div>
    </div>

  );
}

export default RemotionPlayerContainer;
