import { forwardRef, useMemo, useRef } from 'react';
import { CSSProperties } from 'styled-components';
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';

import { AnimationFactory } from '../animation/animation';
import { useGsapTimeline } from '../animation/gsap';
import Text from './text';

gsap.registerPlugin(SplitText);

interface ContentItem {
  items: any;
  itemStyles: any;
  itemAnimations: any;
}

interface ListTextProps {
  data: ContentItem[];
  style: CSSProperties;
  slideDuration: number;
}

const MinimalListicle = forwardRef<HTMLDivElement, ListTextProps>(
  (
    { data, style, slideDuration },
    ref,
  ) => {
    const { items, itemStyles, itemAnimations } = data[0];
    const singularDuration = (slideDuration - 1) / items.length;
    const delays = useMemo(() => {
      let currentDelay = 0;
      return items.map(() => {
        const delayForThisItem = currentDelay;
        currentDelay += singularDuration;
        return delayForThisItem;
      });
    }, [data]);

    const defaultAnimationProps = {
      animations: {
        title: {
          type: 'pan',
          attributes: {
            direction: 'left',
            divider: 'lines',
            stagger: 0.1,
          },
        },
        subtitle: {
          type: 'rise',
          attributes: {
            divider: 'lines',
            stagger: 0.1,
          },
        },
        line: {
          type: 'pan',
          attributes: {
            direction: 'right',
            stagger: 0.1,
          },
        },
        enableExitAnimation: false,
      },
    };

    return (
      <div className="flex flex-col gap-5" style={style} ref={ref}>
        {items.map((item: any, index: any) => {
          const enableExitAnimation = itemAnimations ? itemAnimations[0].animations.enableExitAnimation : defaultAnimationProps.animations.enableExitAnimation;
          return (
            <AnimatedItem
              key={index}
              item={item}
              totalDuration={slideDuration / 2}
              singularDuration={enableExitAnimation ? singularDuration / 2 : singularDuration}
              animation={itemAnimations ? itemAnimations[0] : defaultAnimationProps}
              style={itemStyles[0]}
              delay={enableExitAnimation ? delays[index] / 2 : delays[index]}
            />
          );
        })}
      </div>
    );
  },
);

MinimalListicle.displayName = 'MinimalListicle';
export default MinimalListicle;

const useItemAnimations = (titleRef: any, subtitleRef: any, lineRef: any, animations: any, duration: number, totalDuration: number, delay: number) => {
  useGsapTimeline(() => {
    const animationFactory = AnimationFactory.createAnimation(animations.title.type);
    const { gsapTimeline } = animationFactory.createTimeline(titleRef.current, {
      ...animations.title.attributes,
      delay,
      duration,
    });
    return gsapTimeline;
  });

  useGsapTimeline(() => {
    const animationFactory = AnimationFactory.createAnimation(animations.subtitle.type);
    const { gsapTimeline } = animationFactory.createTimeline(subtitleRef.current, {
      ...animations.subtitle.attributes,
      delay: delay * 1.15,
      duration,

    });
    return gsapTimeline;
  });

  useGsapTimeline(() => {
    const animationFactory = AnimationFactory.createAnimation(animations.line.type);
    const { gsapTimeline } = animationFactory.createTimeline(lineRef.current, {
      ...animations.line?.attributes,
      delay,
      duration,
    });

    return gsapTimeline;
  });

  if (animations?.enableExitAnimation) {
    useGsapTimeline(() => {
      const animationFactory = AnimationFactory.createAnimation('rise');
      const { gsapTimeline } = animationFactory.createTimeline(titleRef.current, {
        ...animations.title.attributes,
        delay: totalDuration + delay,
        duration,
        direction: 'up',
        animate: 'onExit',
      });
      return gsapTimeline;
    });

    useGsapTimeline(() => {
      const animationFactory = AnimationFactory.createAnimation(animations.subtitle.type);
      const { gsapTimeline } = animationFactory.createTimeline(subtitleRef.current, {
        ...animations.subtitle.attributes,
        delay: totalDuration + delay,
        duration,
        direction: 'down',
        animate: 'onExit',

      });
      return gsapTimeline;
    });

    useGsapTimeline(() => {
      const animationFactory = AnimationFactory.createAnimation(animations.line.type);
      const { gsapTimeline } = animationFactory.createTimeline(lineRef.current, {
        ...animations.line?.attributes,
        delay: totalDuration + delay,
        duration,
        direction: 'left',
        animate: 'onExit',
      });

      return gsapTimeline;
    });
  }
};

function AnimatedItem({
  item, delay, style, singularDuration, totalDuration, animation,
}: any) {
  const titleRef = useRef<HTMLDivElement>(null);
  const subtitleRef = useRef<HTMLDivElement>(null);
  const lineRef = useRef<HTMLDivElement>(null);

  useItemAnimations(
    titleRef,
    subtitleRef,
    lineRef,
    animation.animations,
    singularDuration,
    totalDuration,
    delay,
  );

  return (
    <div className="flex items-center gap-3 h-full">
      <div className="flex items-center gap-3">
        <div
          ref={lineRef}
          style={{
            width: '35px',
            height: '3px',
            backgroundColor: 'white',
            marginBottom: '12px',
            flexShrink: 0,
            ...style.lineStyle,
          }}
        />
        <div className='flex-grow'>
          <div className="overflow-hidden">
            <Text ref={titleRef} text={item.title} style={{ fontSize: '20px', color: 'white', ...style.titleStyle }} />
          </div>
          <div className="overflow-hidden">
            <Text ref={subtitleRef} text={item.subtitle} style={{ color: 'white', ...style.subtitleStyle }} />
          </div>
        </div>
      </div>
    </div>
  );
}
