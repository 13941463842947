import React, {
  useEffect, useState, useMemo,
} from 'react';
import {
  Routes, Route, useNavigate, useLocation, Navigate,
} from 'react-router-dom';
import { NextUIProvider } from '@nextui-org/react';

import { User } from 'firebase/auth';
import { auth } from './auth/firebase';
import AuthContext from './auth/context';
import Root from './root';
import Login from './pages/login';
import ChromeLogin from './pages/chrome-login';
import ContentPageV3 from './pages/v3/page';
import Generate from './pages/v3/generate';
import RemotionTest from './pages/remotion-test';
import V2OutputWithSidebar from './pages/v2/output/page';
import Demo1 from './pages/demo/1';
import Demo2 from './pages/demo/2';
import Demo3 from './pages/demo/3';
import Demo4 from './pages/demo/4';
import Demo5 from './pages/demo/5';
import Demo6 from './pages/demo/6';
import Demo7 from './pages/demo/7';
import Demo8 from './pages/demo/8';
import Demo9 from './pages/demo/9';
import Demo10 from './pages/demo/10';
import Demo11 from './pages/demo/11';
import PageCreate from './pages/lica-page/create';
import PageOutput from './pages/lica-page/output';
import EditContent from './pages/v3/edit-content';
import EditVideoConfig from './pages/v3/edit-content/edit-video-config';
import FluxPage from './pages/v3/flux';
import ProductVideo from './pages/v3/product-video';

import './App.css';
import Wall from './pages/v3/wall/wall';
import VideoHighlights from './pages/v3/video-highlights';
import HackathonWall from './pages/v3/wall/hackathon-wall';
import InteractiveDemo from './pages/v3/interactive-demo';

const OPEN_URLS_REGEX = [
  /^\/login$/,
  /^\/wall$/,
  /^\/genai-hack$/,
  /^\/T8R2vcTP49zxRG4CrCqf$/,
  /^\/ext\/login$/,
  /^\/v0\/videos\/[A-Za-z0-9]+\/?$/,
  /^\/v1\/videos\/[A-Za-z0-9]+\/?$/,
  /^\/content\/[A-Za-z0-9]+\/?$/,
  /^\/pages\/[A-Za-z0-9]+\/?$/,
];

function App() {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isAuthCompleted, setIsAuthCompleted] = useState<boolean>(false);
  const [prevLocation, setPrevLocation] = useState<any>();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      setIsAuthCompleted(true);
      if (authUser) {
        setCurrentUser(authUser);
      } else {
        setCurrentUser(null);

        let regex;
        for (regex of OPEN_URLS_REGEX) {
          if (regex.test(window.location.pathname)) {
            return;
          }
        }
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    setPrevLocation(location);

    if (!prevLocation) return;

    if (!currentUser) {
      let regex;
      for (regex of OPEN_URLS_REGEX) {
        if (regex.test(location.pathname)) {
          return;
        }
      }
      navigate('/login');
    }
  }, [location]);

  const authContext = useMemo(() => ({ currentUser }), [currentUser]);

  if (!isAuthCompleted) return null;

  return (
    <AuthContext.Provider value={authContext}>
      <NextUIProvider>
        <Routes>
          <Route element={<Root />}>
            <Route path="v2/content/:videoId/" element={<V2OutputWithSidebar />} />
            <Route path="demo-1" element={<Demo1 />} />
            <Route path="demo-2" element={<Demo2 />} />
            <Route path="demo-3" element={<Demo3 />} />
            <Route path="turing" element={<Demo4 />} />
            <Route path="turing-podcast" element={<Demo5 />} />
            <Route path="lp" element={<PageCreate />} />
            <Route path="lp/:pageId" element={<PageOutput />} />
          </Route>
          <Route path="remotion-test" element={<RemotionTest />} />
          <Route path="login" element={<Login />} />
          <Route path="/" element={<Navigate to="/generate?type=video" replace />} />
          <Route path="genai-hack" element={<HackathonWall />} />
          <Route path="videos" element={<Wall />} />
          <Route path="generate" element={<Generate />} />
          <Route path="interactive-chat" element={<InteractiveDemo />} />
          <Route path="pages/:pageId" element={<ContentPageV3 />} />
          <Route path="T8R2vcTP49zxRG4CrCqf" element={<VideoHighlights />} />
          <Route path="content/:contentId/edit" element={<EditContent />} />
          <Route path="content/:contentId/edit-config" element={<EditVideoConfig />} />
          <Route path="content/:contentId/edit-chat" element={<EditContent />} />
          <Route path="v2/pages/arcSIOATVcHa4BXB2vHX" element={<Demo6 />} />
          <Route path="v2/pages/turing" element={<Demo7 />} />
          <Route path="v2/pages/deepscribe" element={<Demo8 />} />
          <Route path="v2/pages/turing-1" element={<Demo9 />} />
          <Route path="v2/pages/deepscribe-1" element={<Demo10 />} />
          <Route path="v2/pages/yhtrfdgddscHa4BXB2vHX" element={<Demo10 />} />
          <Route path="v2/pages/deepscribe-2" element={<Demo11 />} />
          <Route path="v2/pages/dfsdfsddscHa4BXB2vHX" element={<Demo11 />} />
          <Route path="v2/pages/dsfcsdzfcdfsdcsdzsfe" element={<InteractiveDemo />} />
          <Route path="ext/login" element={<ChromeLogin />} />
          <Route path="flux" element={<FluxPage />} />
          <Route path="product-video" element={<ProductVideo />} />
        </Routes>
      </NextUIProvider>
    </AuthContext.Provider>
  );
}

export default App;
