import { gsap } from 'gsap';

const BASESTYLE = {
  baseX: 0,
  baseY: 0,
  baseR: 0,
  baseRX: 0,
  baseRY: 0,
  baseRZ: 0,
  baseO: 1,
  alpha: 1,
  baseLetterSpacing: 0,
  clipPath: 'inset(0% 0% 0% 0%)',
};

const DEFAULTANIMATRIBUTES = {
  direction: 'up',
  position: 'center',
  divider: 'lines',
  duration: 1,
  stagger: 0,
  ease: 'none',
  opacity: 0,
  animate: 'onEnter',
  delay: 0,
  letterSpacing: 0,
  disappearingFactor: 1,
  expandHeight: false,
  fullSize: false,
  scale: 1,
};

interface IAnimation {
  createTimeline(animatedElement: any, animationAttributes?: InputAttributes, durationSlide?: number): {
    gsapTimeline: gsap.core.Timeline,
    appearingTimeline: number,
    disappearingTimeline: number
  };
}

interface BaseGSAPObject {
  baseX?: number;
  baseY?: number;
  baseO?: number;
  baseR?: number;
  baseRX?: number;
  baseRY?: number;
  baseRZ?: number;
  baseAlpha?: number;
  baseLetterSpacing?: number;

  [key: string]: any;
}

type InputAttributes = {
  direction?: string;
  position?: string;
  divider?: string;
  duration: number;
  stagger?: number;
  ease?: string;
  opacity?: number;
  animate?: string;
  delay?: number,
  letterSpacing?: number,
  disappearingFactor?: number,
  expandHeight?: boolean,
  fullSize?: boolean,
};

function isIterable(obj: any): boolean {
  return typeof obj[Symbol.iterator] === 'function';
}

export function setGSAPTimeline(
  animatedElement: any,
  obj: BaseGSAPObject,
  gsapTimeline: gsap.core.Timeline,
  animationParamsEnter: gsap.TweenVars,
  animationParamsExit: gsap.TweenVars,
  animate: string | undefined,
  durationSlide: number,
): { gsapTimeline: gsap.core.Timeline, appearingTimeline: number, disappearingTimeline: number } {
  const baseProperties = {
    x: obj.baseX,
    y: obj.baseY,
    opacity: obj.baseO,
    rotation: obj.baseR,
    rotationX: obj.baseRX,
    rotationY: obj.baseRY,
    stagger: animationParamsEnter.stagger,
    clipPath: obj.clipPath,
    alpha: obj.baseAlpha,
    ease: animationParamsEnter.ease,
    duration: animationParamsEnter.duration,
    letterSpacing: obj.baseLetterSpacing,
    autoRound: animationParamsEnter.autoRound,
    delay: animationParamsEnter.delay,
    width: animationParamsExit.width,
    height: animationParamsExit.height,
    padding: animationParamsExit.padding,
    scale: animationParamsExit.scale,
  };

  animationParamsEnter.immediateRender = false;

  let enterTimelineEnter;
  let exitTimelineExit;
  let timeToExitBothExit;
  let enterTimelineBoth;
  let exitTimelineBoth;
  let timeToExitBoth;
  let enterTimelineDefault;

  switch (animate) {
    case 'both':
      enterTimelineBoth = gsap.timeline().fromTo(animatedElement, animationParamsEnter, {
        ...baseProperties,
        ease: `${animationParamsEnter.ease}.out`,
      });
      exitTimelineBoth = gsap.timeline().fromTo(animatedElement, baseProperties, {
        ...animationParamsEnter,
        ...animationParamsExit,
        ease: `${animationParamsEnter.ease}.in`,
      });
      timeToExitBoth = durationSlide - Number(exitTimelineBoth.duration());

      return {
        gsapTimeline: gsapTimeline.add(enterTimelineBoth).add(exitTimelineBoth, `<${timeToExitBoth}`),
        appearingTimeline: enterTimelineBoth.duration(),
        disappearingTimeline: exitTimelineBoth.duration(),
      };
    case 'onEnter':
      enterTimelineEnter = gsap.timeline().fromTo(animatedElement, animationParamsEnter, {
        ...baseProperties,
        ease: `${animationParamsEnter.ease}.out`,
      });

      return {
        gsapTimeline: gsapTimeline.add(enterTimelineEnter),
        appearingTimeline: enterTimelineEnter.duration(),
        disappearingTimeline: 0,
      };
    case 'onEnterTwice':
      enterTimelineEnter = gsap.timeline().fromTo(animatedElement, animationParamsEnter, {
        ...animationParamsExit,
        ease: `${animationParamsEnter.ease}.out`,
      })
        .fromTo(animatedElement, animationParamsEnter, {
          ...baseProperties,
          ease: `${animationParamsEnter.ease}.out`,
        });

      return {
        gsapTimeline: gsapTimeline.add(enterTimelineEnter),
        appearingTimeline: enterTimelineEnter.duration(),
        disappearingTimeline: 0,
      };
    case 'onExit':
      exitTimelineExit = gsap.timeline().fromTo(animatedElement, baseProperties, {
        ...animationParamsEnter,
        ...animationParamsExit,
        ease: `${animationParamsEnter.ease}.in`,
      });
      timeToExitBothExit = durationSlide - Number(exitTimelineExit.duration());

      return {
        gsapTimeline: gsapTimeline.add(exitTimelineExit, `<${timeToExitBothExit}`),
        appearingTimeline: 0,
        disappearingTimeline: exitTimelineExit.duration(),
      };
    default:
      enterTimelineDefault = gsap.timeline().fromTo(animatedElement, animationParamsEnter, {
        ...baseProperties,
        ease: `${animationParamsEnter.ease}.out`,
      });

      return {
        gsapTimeline: gsapTimeline.add(enterTimelineDefault),
        appearingTimeline: enterTimelineDefault.duration(),
        disappearingTimeline: 0,
      };
  }
}

class BaseAnimation implements IAnimation {
  gsapTimeline: gsap.core.Timeline;

  direction: string = DEFAULTANIMATRIBUTES.direction;

  exitDuration: number = DEFAULTANIMATRIBUTES.duration;

  animationParams = {
    duration: DEFAULTANIMATRIBUTES.duration,
    opacity: DEFAULTANIMATRIBUTES.opacity,
    stagger: DEFAULTANIMATRIBUTES.stagger,
    delay: DEFAULTANIMATRIBUTES.delay,
    letterSpacing: DEFAULTANIMATRIBUTES.letterSpacing,
    ease: DEFAULTANIMATRIBUTES.ease,
    scale: DEFAULTANIMATRIBUTES.scale,
  };

  constructor() {
    this.gsapTimeline = gsap.timeline();
  }

  createTimeline(animatedElement: any, animationAttributes: InputAttributes, durationSlide?: number): ({
    gsapTimeline: gsap.core.Timeline,
    appearingTimeline: number,
    disappearingTimeline: number
  }) {
    const gsapTimeline = gsap.timeline();

    return {
      gsapTimeline: gsapTimeline as gsap.core.Timeline,
      disappearingTimeline: 0,
      appearingTimeline: 0,
    };
  }

  set(animationAttributes: InputAttributes) {
    if (this.animationParams) {
      this.animationParams.duration = animationAttributes?.duration >= 0 ? Number(animationAttributes?.duration)
        : Number(DEFAULTANIMATRIBUTES.duration);
      this.animationParams.opacity = animationAttributes?.opacity || DEFAULTANIMATRIBUTES.opacity;
      this.animationParams.stagger = animationAttributes?.stagger || DEFAULTANIMATRIBUTES.stagger;
      this.animationParams.delay = animationAttributes?.delay || DEFAULTANIMATRIBUTES.delay;
      this.animationParams.ease = animationAttributes?.ease || DEFAULTANIMATRIBUTES.ease;
      this.animationParams.letterSpacing = animationAttributes?.letterSpacing || DEFAULTANIMATRIBUTES.letterSpacing;
      this.direction = animationAttributes?.direction || DEFAULTANIMATRIBUTES.direction;
      this.exitDuration = animationAttributes?.disappearingFactor ? animationAttributes.duration * animationAttributes.disappearingFactor
        : animationAttributes?.duration || DEFAULTANIMATRIBUTES.duration;
    }
  }

  get() {
    return this;
  }
}

class WipeAnimation extends BaseAnimation {
  animationParams = {
    // @ts-expect-error: super
    ...super.animationParams,
    clipPath: 'inset(0% 0% 0% 0%)',
    autoRound: false,
  };

  createTimeline(animatedElement: any, animationAttributes: InputAttributes, durationSlide?: number): ({
    gsapTimeline: gsap.core.Timeline,
    appearingTimeline: number,
    disappearingTimeline: number
  }) {
    this.set(animationAttributes);

    switch (this.direction) {
      case 'right':
        this.animationParams.clipPath = 'inset(0% 100% 0% 0%)';
        return setGSAPTimeline(
          animatedElement,
          BASESTYLE,
          this.gsapTimeline,
          this.get().animationParams,
          {
            clipPath: 'inset(100% 0% 0% 0%)',
            duration: this.exitDuration,
          },
          animationAttributes?.animate,
          Number(durationSlide),
        );
      case 'left':
        this.animationParams.clipPath = 'inset(0% 0% 0% 100%)';
        return setGSAPTimeline(
          animatedElement,
          BASESTYLE,
          this.gsapTimeline,
          this.get().animationParams,
          {
            clipPath: 'inset(0% 100% 0% 0%)',
            duration: this.exitDuration,
          },
          animationAttributes?.animate,
          Number(durationSlide),
        );
      case 'down':
        this.animationParams.clipPath = 'inset(0% 0% 100% 0%)';
        return setGSAPTimeline(
          animatedElement,
          BASESTYLE,
          this.gsapTimeline,
          this.get().animationParams,
          {
            clipPath: 'inset(100% 0% 0% 0%)',
            duration: this.exitDuration,
          },
          animationAttributes?.animate,
          Number(durationSlide),
        );
      case 'up':
        this.animationParams.clipPath = 'inset(100% 0% 0% 0%)';
        return setGSAPTimeline(
          animatedElement,
          BASESTYLE,
          this.gsapTimeline,
          this.get().animationParams,
          {
            clipPath: 'inset(0% 0% 100% 0%)',
            duration: this.exitDuration,
          },
          animationAttributes?.animate,
          Number(durationSlide),
        );
      default:
        this.animationParams.clipPath = 'inset(0% 0% 100% 0%)';
        return setGSAPTimeline(
          animatedElement,
          BASESTYLE,
          this.gsapTimeline,
          this.get().animationParams,
          {
            clipPath: 'inset(100% 0% 0% 0%)',
            duration: this.exitDuration,
          },
          animationAttributes?.animate,
          Number(durationSlide),
        );
    }
  }
}

class FadeAnimation extends BaseAnimation {
  animationParams = {
    // @ts-expect-error: super
    ...super.animationParams,
  };

  createTimeline(animatedElement: any, animationAttributes: InputAttributes, durationSlide?: number): ({
    gsapTimeline: gsap.core.Timeline,
    appearingTimeline: number,
    disappearingTimeline: number
  }) {
    this.set(animationAttributes);

    return setGSAPTimeline(
      animatedElement,
      BASESTYLE,
      this.gsapTimeline,
      this.get().animationParams,
      { direction: this.exitDuration },
      animationAttributes?.animate,
      Number(durationSlide),
    );
  }
}

class RiseAnimation extends BaseAnimation {
  animationParams = {
    // @ts-expect-error: super
    ...super.animationParams,
    y: '0%',
  };

  createTimeline(animatedElement: any, animationAttributes: InputAttributes, durationSlide?: number): ({
    gsapTimeline: gsap.core.Timeline,
    appearingTimeline: number,
    disappearingTimeline: number
  }) {
    this.set(animationAttributes);
    const fullSize = animationAttributes?.fullSize;

    this.animationParams.y = this.direction === 'up' ? (fullSize ? '100%' : '50px') : this.direction === 'down' ? (fullSize ? '-100%' : '-50px') : (fullSize ? '100%' : '50px');

    return setGSAPTimeline(
      animatedElement,
      BASESTYLE,
      this.gsapTimeline,
      this.get().animationParams,
      {
        y: this.direction === 'up' ? (fullSize ? '-100%' : '-50px') : this.direction === 'down' ? (fullSize ? '100%' : '50px') : (fullSize ? '-100%' : '-50px'),
        duration: this.exitDuration,
      },
      animationAttributes?.animate,
      Number(durationSlide),
    );
  }
}

class PanAnimation extends BaseAnimation {
  animationParams = {
    // @ts-expect-error: super
    ...super.animationParams,
    x: 0,
  };

  createTimeline(animatedElement: any, animationAttributes: InputAttributes, durationSlide?: number): ({
    gsapTimeline: gsap.core.Timeline,
    appearingTimeline: number,
    disappearingTimeline: number
  }) {
    this.set(animationAttributes);

    const direction = animationAttributes?.direction;
    this.animationParams.x = direction === 'left' ? 50 : direction === 'right' ? -50 : 50;

    return setGSAPTimeline(
      animatedElement,
      BASESTYLE,
      this.gsapTimeline,
      this.get().animationParams,
      {
        x: -Number(this.animationParams?.x),
        duration: this.exitDuration,
      },
      animationAttributes?.animate,
      Number(durationSlide),
    );
  }
}

class PanReverseAnimation extends BaseAnimation {
  animationParams = {
    // @ts-expect-error: super
    ...super.animationParams,
    x: '0%',
  };

  createTimeline(animatedElement: any, animationAttributes: InputAttributes, durationSlide?: number): ({
    gsapTimeline: gsap.core.Timeline,
    appearingTimeline: number,
    disappearingTimeline: number
  }) {
    this.set(animationAttributes);

    const direction = animationAttributes?.direction;
    this.animationParams.x = direction === 'left' ? '100%' : direction === 'right' ? '-100%' : '100%';

    return setGSAPTimeline(
      animatedElement,
      BASESTYLE,
      this.gsapTimeline,
      this.get().animationParams,
      {
        x: this.animationParams?.x === '100%' ? '-100%' : '100%',
        opacity: 1,
        duration: this.exitDuration,
      },
      animationAttributes?.animate,
      Number(durationSlide),
    );
  }
}

class TypewriterAnimation extends BaseAnimation {
  animationParams = {
    // @ts-expect-error: super
    ...super.animationParams,
  };

  createTimeline(animatedElement: any, animationAttributes: InputAttributes, durationSlide?: number): ({
    gsapTimeline: gsap.core.Timeline,
    appearingTimeline: number,
    disappearingTimeline: number
  }) {
    this.set(animationAttributes);

    if (isIterable(animatedElement)) {
      this.animationParams.stagger = this.animationParams.duration / animatedElement.length;
    }

    this.animationParams.duration = 0.1;
    this.exitDuration = 0.1;

    const staggerDisappear = animationAttributes.disappearingFactor
      ? this.animationParams.stagger * animationAttributes.disappearingFactor : this.animationParams.stagger;

    return setGSAPTimeline(
      animatedElement,
      BASESTYLE,
      this.gsapTimeline,
      this.get().animationParams,
      { duration: this.exitDuration, stagger: staggerDisappear },
      animationAttributes.animate,
      Number(durationSlide),
    );
  }
}

class AscendAnimation extends BaseAnimation {
  animationParams = {
    // @ts-expect-error: super
    ...super.animationParams,
    y: 0,
  };

  createTimeline(animatedElement: any, animationAttributes: InputAttributes, durationSlide?: number): ({
    gsapTimeline: gsap.core.Timeline,
    appearingTimeline: number,
    disappearingTimeline: number
  }) {
    this.set(animationAttributes);

    this.animationParams.y = this.direction === 'up' ? 50 : this.direction === 'down' ? -50 : 50;

    return setGSAPTimeline(
      animatedElement,
      BASESTYLE,
      this.gsapTimeline,
      this.get().animationParams,
      {
        y: -Number(this.animationParams?.y),
        duration: this.exitDuration,
      },
      animationAttributes?.animate,
      Number(durationSlide),
    );
  }
}

class CreekAnimation extends BaseAnimation {
  animationParams = {
    // @ts-expect-error: super
    ...super.animationParams,
    rotationX: 0,
    transformOrigin: '50% 50%',
    autoRound: false,
  };

  createTimeline(animatedElement: any, animationAttributes: InputAttributes, durationSlide?: number): ({
    gsapTimeline: gsap.core.Timeline,
    appearingTimeline: number,
    disappearingTimeline: number
  }) {
    this.set(animationAttributes);

    gsap.set(animatedElement, {
      transformPerspective: 400,
      perspective: 200,
      transformStyle: 'preserve-3d',
      autoAlpha: 1,
    });

    this.animationParams.rotationX = 90;
    this.animationParams.transformOrigin = '50% 100%';
    this.animationParams.autoRound = false;

    return setGSAPTimeline(
      animatedElement,
      BASESTYLE,
      this.gsapTimeline,
      this.get().animationParams,
      { duration: this.exitDuration, rotationX: -Number(this.animationParams.rotationX) },
      animationAttributes.animate,
      Number(durationSlide),
    );
  }
}

class SpreadAnimation extends BaseAnimation {
  animationParams = {
    // @ts-expect-error: super
    ...super.animationParams,
    autoRound: false,
  };

  createTimeline(animatedElement: any, animationAttributes: InputAttributes, durationSlide?: number): ({
    gsapTimeline: gsap.core.Timeline,
    appearingTimeline: number,
    disappearingTimeline: number
  }) {
    this.set(animationAttributes);

    const tempLetterSpacing = this.animationParams.letterSpacing;

    switch (this.direction) {
      case 'in':
        this.animationParams.letterSpacing = tempLetterSpacing;
        return setGSAPTimeline(
          animatedElement,
          BASESTYLE,
          this.gsapTimeline,
          this.get().animationParams,
          { duration: this.exitDuration },
          animationAttributes?.animate,
          Number(durationSlide),
        );
      case 'out':
        this.animationParams.letterSpacing = 0;
        return setGSAPTimeline(
          animatedElement,
          { ...BASESTYLE, baseLetterSpacing: tempLetterSpacing },
          this.gsapTimeline,
          this.get().animationParams,
          { letterSpacing: tempLetterSpacing, duration: this.exitDuration },
          animationAttributes.animate,
          Number(durationSlide),
        );
      default:
        this.animationParams.letterSpacing = 0;
        return setGSAPTimeline(
          animatedElement,
          { ...BASESTYLE, baseLetterSpacing: tempLetterSpacing },
          this.gsapTimeline,
          this.get().animationParams,
          { letterSpacing: tempLetterSpacing, duration: this.exitDuration },
          animationAttributes.animate,
          Number(durationSlide),
        );
    }
  }
}

class ExpandAnimation extends BaseAnimation {
  animationParams = {
    // @ts-expect-error: super
    ...super.animationParams,
    width: 0,
    height: 0,
    rotateZ: 0,
    padding: '0',
  };

  createTimeline(animatedElement: any, animationAttributes: InputAttributes, durationSlide?: number): ({
    gsapTimeline: gsap.core.Timeline,
    appearingTimeline: number,
    disappearingTimeline: number
  }) {
    const direction = animationAttributes?.direction;
    const expandHeight = animationAttributes?.expandHeight;
    const timelines = <any>[];
    const appearingTimelines = <any>[];
    const disappearingTimelines = <any>[];

    const elements = Array.isArray(animatedElement) ? animatedElement : [animatedElement];

    elements.forEach((element: HTMLElement) => {
      let initialHeight = 0;
      let initialWidth = 0;
      let initialPadding = '0';

      if (element instanceof HTMLElement) {
        initialHeight = element.offsetHeight;
        initialWidth = element.offsetWidth;
        initialPadding = element.style.padding;
      }

      this.set(animationAttributes);
      this.animationParams.rotateZ = direction === 'left' ? -5 : direction === 'right' ? 5 : 0;
      this.animationParams.height = expandHeight ? 0 : initialHeight;

      const { gsapTimeline, appearingTimeline, disappearingTimeline } = setGSAPTimeline(
        element,
        BASESTYLE,
        this.gsapTimeline,
        this.get().animationParams,
        {
          width: initialWidth + 1,
          height: initialHeight,
          rotateZ: 0,
          duration: this.exitDuration,
          padding: initialPadding,
        },
        animationAttributes?.animate,
        Number(durationSlide),
      );

      timelines.push(gsapTimeline);
      appearingTimelines.push(appearingTimeline);
      disappearingTimelines.push(disappearingTimeline);
    });

    return {
      gsapTimeline: gsap.timeline().add(timelines),
      appearingTimeline: Math.max(...appearingTimelines),
      disappearingTimeline: Math.max(...disappearingTimelines),
    };
  }
}

class BreatheAnimation extends BaseAnimation {
  animationParams = {
    // @ts-expect-error: super
    ...super.animationParams,
  };

  createTimeline(animatedElement: any, animationAttributes: InputAttributes, durationSlide?: number): ({
    gsapTimeline: gsap.core.Timeline,
    appearingTimeline: number,
    disappearingTimeline: number
  }) {
    this.set(animationAttributes);
    this.animationParams.opacity = 1;
    this.animationParams.duration = 10;
    this.animationParams.scale = 1.15;

    return setGSAPTimeline(
      animatedElement,
      BASESTYLE,
      this.gsapTimeline,
      this.get().animationParams,
      {
        scale: 1,
        ease: 'power4.inOut',
        duration: this.exitDuration,
      },
      animationAttributes?.animate,
      Number(durationSlide),
    );
  }
}

class DefaultAnimation extends BaseAnimation {}

export class AnimationFactory {
  static createAnimation(type: string): IAnimation {
    switch (type) {
      case 'wipe':
        return new WipeAnimation();
      case 'rise':
        return new RiseAnimation();
      case 'pan':
        return new PanAnimation();
      case 'fade':
        return new FadeAnimation();
      case 'typewriter':
        return new TypewriterAnimation();
      case 'ascend':
        return new AscendAnimation();
      case 'spread':
        return new SpreadAnimation();
      case 'creek':
        return new CreekAnimation();
      case 'panreverse':
        return new PanReverseAnimation();
      case 'expand':
        return new ExpandAnimation();
      case 'breathe':
        return new BreatheAnimation();
      default:
        return new DefaultAnimation();
    }
  }
}
