import React, {
  Fragment, useEffect, useState, useContext,
} from 'react';
import { Button, Input, Spacer } from '@nextui-org/react';
import { Icon } from '@iconify/react';
import cloneDeep from 'lodash/cloneDeep';
import { Alert, Snackbar } from '@mui/material';

import AuthContext from '@/auth/context';
import { isHyperlink } from '@/utils/common';
import useFetch from '@/hooks/useFetch';

import styles from './index.module.css';

export const COMPONENT_TYPE = {
  Image: 'IMAGE',
  Text: 'TEXT',
  Listicle: ['MINIMAL_LISTICLE', 'IMAGE_MINIMAL_LISTICLE'],
  TagsListicle: ['TAGS_LISTICLE', 'TAGS_BULLETS'],
  Card: 'CARD',
  Video: 'VIDEO',
  Group: 'GROUP',
  SVG: 'SVG',
};

const filterComponentsByType = (components: any, type: string | string[]) => {
  const comps: any[] = [];

  components.forEach((component: any) => {
    if (Array.isArray(type) && type.includes(component.type)) {
      comps.push(component);
    } else if (component.type === type) {
      comps.push(component);
    } else if (component.type === COMPONENT_TYPE.Group) {
      const groupComps: any[] = filterComponentsByType(component.components, type);
      comps.push(...groupComps);
    }
  });

  return comps;
};

const getUpdatedComponents = (
  components: any,
  textComponentsById: any,
  imageComponentsById: any,
  listicleComponentsById: any,
  tagsListicleComponentsById: any,
) => {
  const comps: any[] = [];

  components.forEach((component: any) => {
    if (component.type === COMPONENT_TYPE.Text) {
      comps.push(textComponentsById[component.id]);
    } else if (
      component.type === COMPONENT_TYPE.Image
      || component.type === COMPONENT_TYPE.SVG
      || component.type === COMPONENT_TYPE.Video
    ) {
      comps.push(imageComponentsById[component.id]);
    } else if (COMPONENT_TYPE.Listicle.includes(component.type)) {
      comps.push(listicleComponentsById[component.id]);
    } else if (COMPONENT_TYPE.TagsListicle.includes(component.type)) {
      comps.push(tagsListicleComponentsById[component.id]);
    } else if (component.type === COMPONENT_TYPE.Group) {
      component.components = [
        ...getUpdatedComponents(
          component.components,
          textComponentsById,
          imageComponentsById,
          listicleComponentsById,
          tagsListicleComponentsById,
        ),
      ];
      comps.push(component);
    } else {
      comps.push(component);
    }
  });

  return comps;
};

export default function EditSlideItem({
  slide, index, onDelete, onSave, updateVideo, fetchContent,
  isProcessing, setIsProcessing, unhydratedSlideLayout, screenplaySlide,
  showSlideEditingModal, setEditSlideIndex, onDuplicateSlide,
}: {
  slide: any;
  index: number;
  onSave: (slide: any, updatedScreenplay: any, index: number) => void;
  onDelete: (index: number) => void;
  updateVideo: any;
  fetchContent: any;
  isProcessing: boolean;
  setIsProcessing: (val: boolean) => void;
  unhydratedSlideLayout: any;
  screenplaySlide: any;
  showSlideEditingModal: boolean;
  setEditSlideIndex: any;
  onDuplicateSlide: any;
}) {
  const authContext = useContext(AuthContext);

  const [open, setOpen] = useState(false);

  const [chatPrompt, setChatPrompt] = useState<string>('');

  const [openEditImageModal, setOpenEditImageModal] = useState(false);
  const [editImageItem, setEditImageItem] = useState<any>({});
  const [textComponents, setTextComponents] = useState<any[]>([]);
  const [listicleComponents, setListicleComponents] = useState<any[]>([]);
  const [tagsListicleComponents, setTagsListicleComponents] = useState<any[]>([]);
  const [imageComponents, setImageComponents] = useState<any[]>([]);
  const [voiceover, setVoiceover] = useState<any[]>(slide.voiceover || '');
  const [isSnackbarVisible, setIsSnackbarVisible] = useState<boolean>(false);
  const [isMediaLoading, setIsMediaLoading] = useState<boolean>(false);
  const [useLocalMedia, setUseLocalMedia] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});

  const { fetchData: downloadImage } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/images/`,
    { method: 'POST' },
  );

  const initialiseComponents = () => {
    setTextComponents(filterComponentsByType(slide.components, COMPONENT_TYPE.Text));
    setListicleComponents(filterComponentsByType(slide.components, COMPONENT_TYPE.Listicle));
    setTagsListicleComponents(
      filterComponentsByType(slide.components, COMPONENT_TYPE.TagsListicle),
    );

    setImageComponents([
      ...filterComponentsByType(slide.components, COMPONENT_TYPE.Image),
      ...filterComponentsByType(slide.components, COMPONENT_TYPE.SVG),
      ...filterComponentsByType(slide.components, COMPONENT_TYPE.Video),
    ]);
  };

  useEffect(() => {
    initialiseComponents();
  }, [slide, index, open]);

  const getComponentToAttributeMap = (
    components: any[],
    componentIdToAttributeMap: Map<string, string>,
  ) => {
    components?.forEach((component: any) => {
      if (component.type === COMPONENT_TYPE.Group) {
        getComponentToAttributeMap(component.components, componentIdToAttributeMap);
      } else if (component.type === COMPONENT_TYPE.Text) {
        if (component.text && component.text.length > 0) {
          if (component.text[0] === '{' || component.text[-1] === '}') {
            componentIdToAttributeMap.set(component.id, component.text.slice(1, -1));
          }
        }
      } else if (
        component.type === COMPONENT_TYPE.Image
        || component.type === COMPONENT_TYPE.Video
        || component.type === COMPONENT_TYPE.SVG
      ) {
        if (component.src[0] === '{' || component.src[-1] === '}') {
          componentIdToAttributeMap.set(component.id, component.src.slice(1, -1));
        }
      } else if (COMPONENT_TYPE.Listicle.includes(component.type)) {
        if (component.data[0].items[0] === '{' || component.data[0].items[-1] === '}') {
          componentIdToAttributeMap.set(component.id, component.data[0].items.slice(1, -1));
        }
      } else if (COMPONENT_TYPE.TagsListicle.includes(component.type)) {
        if (component.data.items[0] === '{' || component.data.items[-1] === '}') {
          componentIdToAttributeMap.set(component.id, component.data.items.slice(1, -1));
        }
      }
    });
  };

  const getUpdatedAttributesMap = (
    components: any[],
    componentIdToAttributeMap: Map<string, string>,
    updatedAttributesMap: Map<string, string>,
  ) => {
    components?.forEach((component: any) => {
      if (component.type === COMPONENT_TYPE.Group) {
        getUpdatedAttributesMap(
          component.components,
          componentIdToAttributeMap,
          updatedAttributesMap,
        );
      } else if (componentIdToAttributeMap.has(component.id)) {
        if (component.type === COMPONENT_TYPE.Text) {
          updatedAttributesMap.set(componentIdToAttributeMap.get(component.id)!, component.text);
        } else if (
          component.type === COMPONENT_TYPE.Image
          || component.type === COMPONENT_TYPE.Video
          || component.type === COMPONENT_TYPE.SVG
        ) {
          updatedAttributesMap.set(componentIdToAttributeMap.get(component.id)!, component.src);
        } else if (COMPONENT_TYPE.Listicle.includes(component.type)) {
          updatedAttributesMap.set(
            componentIdToAttributeMap.get(component.id)!,
            component.data[0].items,
          );
        } else if (COMPONENT_TYPE.TagsListicle.includes(component.type)) {
          updatedAttributesMap.set(
            componentIdToAttributeMap.get(component.id)!,
            component.data.items,
          );
        }
      }
    });
  };

  const getUpdatedScreenplayAttributes = (updatedSlideComponents: any) => {
    const componentIdToAttributeMap = new Map<string, string>();
    getComponentToAttributeMap(unhydratedSlideLayout.components, componentIdToAttributeMap);

    const updatedAttributesMap = new Map<string, string>();
    getUpdatedAttributesMap(
      updatedSlideComponents,
      componentIdToAttributeMap,
      updatedAttributesMap,
    );

    return updatedAttributesMap;
  };

  const onSaveSlide = (isKeepClose: boolean = false) => {
    if (!open && !isKeepClose) {
      setOpen(true);
      return;
    }

    if (screenplaySlide.voiceover !== voiceover) {
      setIsSnackbarVisible(true);
    }

    const textComponentsById = textComponents.reduce(
      (prev: any, cur: any) => ({ ...prev, [cur.id]: cur }),
      {},
    );
    const listicleComponentsById = listicleComponents.reduce(
      (prev: any, cur: any) => ({ ...prev, [cur.id]: cur }),
      {},
    );
    const tagsListicleComponentsById = tagsListicleComponents.reduce(
      (prev: any, cur: any) => ({ ...prev, [cur.id]: cur }),
      {},
    );
    const imageComponentsById = imageComponents.reduce(
      (prev: any, cur: any) => ({ ...prev, [cur.id]: cur }),
      {},
    );

    const updatedSlideComponents = getUpdatedComponents(
      slide.components,
      textComponentsById,
      imageComponentsById,
      listicleComponentsById,
      tagsListicleComponentsById,
    );

    const updatedScreenplayAttributes = getUpdatedScreenplayAttributes(updatedSlideComponents);
    const updatedScreenplay = {
      ...screenplaySlide,
      attributes: {
        ...Object.fromEntries(updatedScreenplayAttributes),
      },
      voiceover,
    };

    onSave(
      {
        ...slide,
        components: [...updatedSlideComponents],
        voiceover,
      },
      updatedScreenplay,
      index,
    );

    setOpen(false);
  };

  const onSetTextChange = (componentIndex: number, value: string) => {
    const cloneTexts = [...textComponents] as any;
    cloneTexts[componentIndex] = { ...cloneTexts[componentIndex], text: value };
    setTextComponents(cloneTexts);
  };

  const onSetListicleChange = (
    componentIndex: number,
    itemIndex: number,
    attribute: string,
    value: string,
  ) => {
    const listicleComponent = cloneDeep(listicleComponents[componentIndex]);
    listicleComponent.data[0].items[itemIndex][attribute] = value;
    setListicleComponents([
      ...listicleComponents.slice(0, componentIndex),
      listicleComponent,
      ...listicleComponents.slice(componentIndex + 1),
    ]);
  };

  const onSetTagsListicleChange = (
    componentIndex: number,
    itemIndex: number,
    attribute: string,
    value: string,
  ) => {
    const listicleComponent = cloneDeep(tagsListicleComponents[componentIndex]);
    listicleComponent.data.items[itemIndex][attribute] = value;
    setTagsListicleComponents([
      ...tagsListicleComponents.slice(0, componentIndex),
      listicleComponent,
      ...tagsListicleComponents.slice(componentIndex + 1),
    ]);
  };

  const onListItemDuplicate = (componentIndex: number, itemIndex: number) => {
    const listicleComponent = cloneDeep(listicleComponents[componentIndex]);
    listicleComponent.data[0].items = [
      ...listicleComponent.data[0].items,
      {
        ...listicleComponent.data[0].items[itemIndex],
      },
    ];
    setListicleComponents([
      ...listicleComponents.slice(0, componentIndex),
      listicleComponent,
      ...listicleComponents.slice(componentIndex + 1),
    ]);
  };

  const onListItemDelete = (componentIndex: number, itemIndex: number) => {
    const listicleComponent = cloneDeep(listicleComponents[componentIndex]);
    listicleComponent.data[0].items = [
      ...listicleComponent.data[0].items.slice(0, itemIndex),
      ...listicleComponent.data[0].items.slice(itemIndex + 1),
    ];
    setListicleComponents([
      ...listicleComponents.slice(0, componentIndex),
      listicleComponent,
      ...listicleComponents.slice(componentIndex + 1),
    ]);
  };

  const onTagsListItemDuplicate = (componentIndex: number, itemIndex: number) => {
    const listicleComponent = cloneDeep(tagsListicleComponents[componentIndex]);
    listicleComponent.data.items = [
      ...listicleComponent.data.items,
      {
        ...listicleComponent.data.items[itemIndex],
      },
    ];
    setTagsListicleComponents([
      ...tagsListicleComponents.slice(0, componentIndex),
      listicleComponent,
      ...tagsListicleComponents.slice(componentIndex + 1),
    ]);
  };

  const onTagsListItemDelete = (componentIndex: number, itemIndex: number) => {
    const listicleComponent = cloneDeep(tagsListicleComponents[componentIndex]);
    listicleComponent.data.items = [
      ...listicleComponent.data.items.slice(0, itemIndex),
      ...listicleComponent.data.items.slice(itemIndex + 1),
    ];
    setTagsListicleComponents([
      ...tagsListicleComponents.slice(0, componentIndex),
      listicleComponent,
      ...tagsListicleComponents.slice(componentIndex + 1),
    ]);
  };

  const onOpenEditImage = (
    componentId: string,
    src: string,
    componentType: string,
    mediaIndex?: number,
  ): void => {
    setOpenEditImageModal(true);
    setEditImageItem({
      componentId,
      src,
      componentType,
      mediaIndex,
    });
  };

  const toggleModal = () => setOpenEditImageModal(!openEditImageModal);

  const openChatModal = () => {
    setEditSlideIndex(index);
  };

  const closeChatModal = () => {
    setEditSlideIndex(null);
  };

  const handleMediaUpload = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setUseLocalMedia(true);
      const currFormData = new FormData();
      currFormData.append('file', file);
      setFormData(currFormData);
    }
  };

  const onSaveEditImage = async () => {
    const token = await authContext.currentUser?.getIdToken();
    if (!token) return;

    setIsMediaLoading(true);

    let mediaSource = editImageItem.src;

    if (useLocalMedia) {
      const uploadResponse = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/api/v1/upload/`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        },
      ).then((res) => res.json());
      mediaSource = uploadResponse.data.url;
    } else {
      const downloadResponse = await downloadImage({ body: JSON.stringify(editImageItem.src) });

      mediaSource = downloadResponse.data.url;
    }

    if (editImageItem.componentType === 'IMAGE_MINIMAL_LISTICLE') {
      const updatedListicleComponents = listicleComponents.map((component) => {
        if (component.id === editImageItem.componentId) {
          const updatedItems = component.data[0].items.map((item: any, idx: any) => {
            if (idx === editImageItem.mediaIndex) {
              return { ...item, image_keyword: mediaSource };
            }
            return item;
          });
          return {
            ...component,
            data: [
              {
                ...component.data[0],
                items: updatedItems,
              },
              ...component.data.slice(1),
            ],
          };
        }
        return component;
      });

      setListicleComponents(updatedListicleComponents);
    } else if (editImageItem.componentType === 'TAGS_LISTICLE') {
      const updatedListicleComponents = tagsListicleComponents.map((component) => {
        if (component.id === editImageItem.componentId) {
          const updatedItems = component.data.items.map((item: any, idx: any) => {
            if (idx === editImageItem.mediaIndex) {
              return { ...item, image_keyword: mediaSource };
            }
            return item;
          });
          return {
            ...component,
            data: {
              ...component.data,
              items: updatedItems,
            },
          };
        }
        return component;
      });

      setTagsListicleComponents(updatedListicleComponents);
    } else {
      const updatedImageComponents = imageComponents.map((component) => {
        if (component.id === editImageItem.componentId) {
          return {
            ...component,
            src: mediaSource,
          };
        }
        return component;
      });

      setImageComponents(updatedImageComponents);
    }

    setUseLocalMedia(false);
    setIsMediaLoading(false);
    setEditImageItem({});
    setOpenEditImageModal(false);
  };

  const handleVideoUpdate = async () => {
    if (isProcessing) return;

    setIsProcessing(true);

    const updatedPrompt = `Edit slide ${index + 1} with the following instructions - ${chatPrompt}`;
    const payload = {
      prompt: updatedPrompt,
    };

    await updateVideo({
      body: JSON.stringify(payload),
    });

    await fetchContent(true);
  };

  const onDuplicate = () => {
    if (open) {
      setOpen(false);
      return;
    }
    onDuplicateSlide(index);
  };

  const editableImageComponents = imageComponents.filter((item) => item.editable !== false);

  return (
    <div className={styles.slideItemContainer}>
      <div>
        {openEditImageModal && (
          <div className={styles.modalOverlay} onClick={toggleModal}>
            <div className={`bg-secondary ${styles.modal}`} onClick={(e) => e.stopPropagation()}>
              <Input
                type="text"
                radius="sm"
                label="Media URL"
                className="!text-secondary"
                placeholder="https://media.com/image"
                labelPlacement="outside"
                startContent={<Icon icon="ph:link-bold" />}
                value={editImageItem.src}
                isInvalid={!isHyperlink(editImageItem.src)}
                onValueChange={(value) => {
                  setEditImageItem({
                    ...editImageItem,
                    src: value,
                  });
                }}
              />
              <Spacer y={3} />
              <input
                type="file"
                onChange={handleMediaUpload}
                className="w-full bg-[#000000]"
              />
              <div className="w-full flex justify-end items-center gap-2">
                <Button
                  size="md"
                  className="!text-secondary !bg-primary rounded-full"
                  variant="light"
                  onClick={toggleModal}
                >
                  Cancel
                </Button>
                <Button
                  size="md"
                  className="!text-secondary !bg-primary rounded-full"
                  onClick={onSaveEditImage}
                  isLoading={isMediaLoading}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        )}
        {showSlideEditingModal && (
          <div className={styles.modalOverlay}>
            <div className={`bg-secondary ${styles.modal}`} onClick={(e) => e.stopPropagation()}>
              <textarea
                placeholder="Describe the changes you would like to make"
                className="p-1 border-1 rounded-[4px] outline-none border-[#eaecf049] mb-2 w-full bg-[#000000] h-20 resize-none"
                value={chatPrompt}
                onChange={(e) => setChatPrompt(e.target.value)}
              />
              <div className="w-full flex justify-end items-center gap-2">
                <Button
                  size="md"
                  className="!text-secondary !bg-primary rounded-full"
                  variant="light"
                  onClick={() => { closeChatModal(); }}
                >
                  Cancel
                </Button>
                <Button
                  size="md"
                  className="!text-secondary !bg-primary rounded-full"
                  onClick={handleVideoUpdate}
                  isLoading={isProcessing}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.slideItemIndex}>
        <div>
          Scene -
          {' '}
          {index + 1}
        </div>
        <div className="flex gap-2">
          <Button
            onClick={() => openChatModal()}
            size="sm"
            color="default"
            className="text-primary"
            isIconOnly
            style={{
              borderRadius: '50%',
              padding: '0',
            }}
          >
            <Icon icon="ph:chat-bold" style={{ fontSize: '1rem' }} />
          </Button>
          <Button
            onClick={() => onDelete(index)}
            size="sm"
            color="default"
            className="text-primary bg-danger"
            isIconOnly
            style={{
              borderRadius: '50%',
              padding: '0',
            }}
          >
            <Icon icon="uiw:delete" style={{ fontSize: '1rem' }} />
          </Button>
          <Button
            onClick={onDuplicate}
            size="sm"
            color="default"
            className="text-primary bg-tertiary"
            isIconOnly
            style={{
              borderRadius: '50%',
              padding: '0',
            }}
          >
            <Icon icon={open ? 'ep:close-bold' : 'heroicons-outline:duplicate'} style={{ fontSize: '1rem' }} />
          </Button>
          <Button
            onClick={() => onSaveSlide(false)}
            size="sm"
            color={open ? 'default' : 'primary'}
            className={open ? '!text-primary !bg-tertiary' : '!text-primary !bg-tertiary'}
            isIconOnly
            style={{
              borderRadius: '50%',
              padding: '0',
              background: open ? 'hsl(var(--nextui-primary)' : 'white',
            }}
          >
            <Icon
              icon={open ? 'material-symbols:check-circle-outline' : 'iconamoon:edit'}
              style={{ fontSize: '1rem' }}
            />
          </Button>
        </div>
      </div>
      {open ? (
        <div>
          <div className={styles.slideItemImageSlideContainer}>
            {imageComponents.map((image: any) => {
              if (image.editable === false) return null;

              return (
                <div className={styles.slideItemImage} key={`${image.src}-${image.id}`}>
                  {image.src?.match(/\.(mp4|mov|webm|ogg)$/i) ? (
                    <video>
                      <source
                        src={`${image.src}#t=0.1`}
                        type={`video/${image.src?.split('.').pop()}`}
                      />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img src={image.src} alt="Media content" />
                  )}
                  <div className={styles.editImageButton}>
                    <Button
                      onClick={() => onOpenEditImage(image.id, image.src, COMPONENT_TYPE.Image)}
                      size="sm"
                      color="primary"
                      className="text-primary"
                      isIconOnly
                      style={{
                        borderRadius: '50%',
                        padding: '0',
                        background: 'white',
                      }}
                    >
                      <Icon icon="iconamoon:edit" style={{ fontSize: '1rem' }} />
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="h-4" />
        </div>
      ) : (
        editableImageComponents.length > 0 ? (
          <div className={styles.slideItemImagePreviewContainer}>
            <div className={styles.slideItemImage}>
              {editableImageComponents[0].src?.match(/\.(mp4|mov|webm|ogg)$/i) ? (
                <video>
                  <source
                    src={`${editableImageComponents[0].src}#t=0.1`}
                    type={`video/${editableImageComponents[0].src?.split('.').pop()}`}
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img src={editableImageComponents[0].src} alt="Media content" />
              )}
            </div>
            <div className="h-4" />
          </div>
        ) : null
      )}
      <div className="w-full text-wrap break-words overflow-hidden">
        {textComponents && textComponents.map((item: any, itemIndex: number) => (
          <div className={styles.slideItemTitle} key={item.id}>
            {open ? (
              <input
                value={item.text}
                className={`${styles.input} p-1 border-1 rounded-[4px] outline-none border-[#eaecf049] mb-2`}
                onChange={(e) => onSetTextChange(itemIndex, e.target.value)}
              />
            ) : <p className="mb-1">{item.text}</p>}
          </div>
        ))}
        {listicleComponents && listicleComponents.map((component: any, i: number) => (
          <Fragment key={component.id}>
            <div className="font-bold mb-2">Listicle</div>
            {component.data[0].items.map((item: any, j: number) => (
              <div className="w-full overflow-auto" key={`${i}-${j}`}>
                {open ? (
                  <div className="flex gap-2 items-center">
                    <div className="flex-auto">
                      {('title' in item) && (
                        <input
                          value={item.title || ''}
                          className={`${styles.input} p-1 border-1 rounded-[4px] outline-none border-[#eaecf049] mb-2`}
                          onChange={(e) => onSetListicleChange(i, j, 'title', e.target.value)}
                        />
                      )}
                      {('subtitle' in item) && (
                        <input
                          value={item.subtitle || ''}
                          className={`${styles.input} text-sm p-1 border-1 rounded-[4px] outline-none border-[#eaecf049] mb-2`}
                          onChange={(e) => onSetListicleChange(i, j, 'subtitle', e.target.value)}
                        />
                      )}
                      {('image_keyword' in item) && (
                        <div className={styles.slideItemImage} key={`${item.image_keyword}-${index}`}>
                          {item.image_keyword.match(/\.(mp4|mov|webm|ogg)$/i) ? (
                            <video>
                              <source
                                src={item.image_keyword}
                                type={`video/${item.image_keyword.split('.').pop()}`}
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img src={item.image_keyword} alt="Media content" />
                          )}
                          <div className={styles.editImageButton}>
                            <Button
                              onClick={() => onOpenEditImage(component.id, item.image_keyword, 'IMAGE_MINIMAL_LISTICLE', j)}
                              size="sm"
                              color="primary"
                              className="text-primary outline-none"
                              isIconOnly
                              style={{
                                borderRadius: '50%',
                                padding: '0',
                                background: 'white',
                              }}
                            >
                              <Icon icon="iconamoon:edit" style={{ fontSize: '1rem' }} />
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex gap-2">
                      <Button
                        onClick={() => onListItemDuplicate(i, j)}
                        size="sm"
                        color="default"
                        className="text-primary bg-tertiary"
                        isIconOnly
                        style={{
                          borderRadius: '50%',
                          padding: '0',
                        }}
                      >
                        <Icon icon="heroicons-outline:duplicate" style={{ fontSize: '12px' }} />
                      </Button>
                      <Button
                        onClick={() => onListItemDelete(i, j)}
                        size="sm"
                        color="default"
                        className="text-primary bg-tertiary"
                        isIconOnly
                        style={{
                          borderRadius: '50%',
                          padding: '0',
                        }}
                      >
                        <Icon icon="uiw:delete" style={{ fontSize: '12px' }} />
                      </Button>
                    </div>
                  </div>
                ) : (
                  <>
                    {item.title && <p className="mb-2">{item.title}</p>}
                    {item.subtitle && <p className="text-sm mb-2">{item.subtitle}</p>}
                    {item.image_keyword && (
                      <div className={styles.slideItemImagePreviewContainer}>
                        <div className={styles.slideItemImage}>
                          {item.image_keyword.match(/\.(mp4|mov|webm|ogg)$/i) ? (
                            <video>
                              <source
                                src={item.image_keyword}
                                type={`video/${item.image_keyword.split('.').pop()}`}
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img src={item.image_keyword} alt="Media content" />
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div className="h-4" />
              </div>
            ))}
          </Fragment>
        ))}
        {tagsListicleComponents && tagsListicleComponents.map((component: any, i: number) => (
          <Fragment key={component.id}>
            <div className="font-bold mb-2">Listicle</div>
            {component.data.items.map((item: any, j: number) => (
              <div className="w-full overflow-auto" key={`${component.id}-${j}`}>
                {open ? (
                  <div className="flex gap-2 items-center">
                    <div className="flex-auto">
                      {('title' in item) && (
                        <input
                          value={item.title || ''}
                          className={`${styles.input} p-1 border-1 rounded-[4px] outline-none border-[#eaecf049] mb-2`}
                          onChange={(e) => onSetTagsListicleChange(i, j, 'title', e.target.value)}
                        />
                      )}
                      {('subtitle' in item) && (
                        <input
                          value={item.subtitle || ''}
                          className={`${styles.input} text-sm p-1 border-1 rounded-[4px] outline-none border-[#eaecf049] mb-2`}
                          onChange={(e) => onSetTagsListicleChange(i, j, 'subtitle', e.target.value)}
                        />
                      )}
                      {('image_keyword' in item) && (
                        <div className={styles.slideItemImage} key={`${item.image_keyword}-${index}`}>
                          {item.image_keyword.match(/\.(mp4|mov|webm|ogg)$/i) ? (
                            <video>
                              <source
                                src={item.image_keyword}
                                type={`video/${item.image_keyword.split('.').pop()}`}
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img src={item.image_keyword} alt="Media content" />
                          )}
                          <div className={styles.editImageButton}>
                            <Button
                              onClick={() => onOpenEditImage(component.id, item.image_keyword, 'TAGS_LISTICLE', j)}
                              size="sm"
                              color="primary"
                              className="text-primary outline-none"
                              isIconOnly
                              style={{
                                borderRadius: '50%',
                                padding: '0',
                                background: 'white',
                              }}
                            >
                              <Icon icon="iconamoon:edit" style={{ fontSize: '1rem' }} />
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex gap-2">
                      <Button
                        onClick={() => onTagsListItemDuplicate(i, j)}
                        size="sm"
                        color="default"
                        className="text-primary bg-tertiary"
                        isIconOnly
                        style={{
                          borderRadius: '50%',
                          padding: '0',
                        }}
                      >
                        <Icon icon="heroicons-outline:duplicate" style={{ fontSize: '12px' }} />
                      </Button>
                      <Button
                        onClick={() => onTagsListItemDelete(i, j)}
                        size="sm"
                        color="default"
                        className="text-primary bg-tertiary"
                        isIconOnly
                        style={{
                          borderRadius: '50%',
                          padding: '0',
                        }}
                      >
                        <Icon icon="uiw:delete" style={{ fontSize: '12px' }} />
                      </Button>
                    </div>
                  </div>
                ) : (
                  <>
                    {item.title && <p className="mb-1">{item.title}</p>}
                    {item.subtitle && <p className="text-sm mb-1">{item.subtitle}</p>}
                    {item.image_keyword && (
                      <div className={styles.slideItemImagePreviewContainer}>
                        <div className={styles.slideItemImage}>
                          {item.image_keyword.match(/\.(mp4|mov|webm|ogg)$/i) ? (
                            <video>
                              <source
                                src={item.image_keyword}
                                type={`video/${item.image_keyword.split('.').pop()}`}
                              />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img src={item.image_keyword} alt="Media content" />
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div className="h-4" />
              </div>
            ))}
          </Fragment>
        ))}
        <div className={`${!open && 'hidden'} my-4 items-start gap-2`}>
          <div className="font-bold mb-1">Voiceover</div>
          <textarea
            className="resize-none w-full border-1 h-[100px] rounded-[4px] p-[4px] no-scrollbar outline-none border-[#eaecf049]"
            style={{ background: 'transparent' }}
            value={voiceover}
            onChange={(e: any) => setVoiceover(e.target.value)}
          />
        </div>
      </div>
      <Snackbar
        open={isSnackbarVisible}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        autoHideDuration={1500}
        onClose={() => {
          setIsSnackbarVisible(false);
        }}
      >
        <Alert severity="info">Click Save Changes to update the voiceover</Alert>
      </Snackbar>
    </div>
  );
}
